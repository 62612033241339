import React, { PureComponent } from 'react';
import SimpleLineIcon from 'react-simple-line-icons';
import { Container, Title, Text } from './approvisionnement.styles';
import { LineContainer, LineTitle, LineValue } from './approvisionnement.styles';


const Line = ({ title, value }) => {
  if (!value)
    return null;
  return (
    <LineContainer>
      <LineTitle>{title}</LineTitle>
      <LineValue>{value}</LineValue>
    </LineContainer>
  );
};

class Approvisionnement extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    return (
      <Container>
        <Title>
          <SimpleLineIcon name={'basket-loaded '} color={'#4a4a4a'} style={{fontSize: '14px', marginRight: '8px'}} />
          <Text>Approvisionnement</Text>
        </Title>
        <Line title={'Fréquence d’approvisionnement par semaine:'} value={data.get('provisioning_frequency')} />
        <Line title={'Budget par semaine:'} value={data.get('buy_capacity') ? data.get('buy_capacity').get('name') : null} />
      </Container>
    );
  }
}

export default Approvisionnement;
