import React, { PureComponent } from 'react';
import Chart from 'react-google-charts';
import { Container, Title, ChartContainer } from './visits-chart.styles';

const DATA = [
  ['', ''],
  ['Sunday', 2500],
  ['Monday', 1200],
  ['Tuesday', 1000],
  ['Wednesday', 2800],
  ['Thursday', 1400],
  ['Friday', 2000],
  ['Saturday', 2000]
];

class VisitsChart extends PureComponent {
  render() {
    return (
      <Container>
        <Title>Visits Chart</Title>
        <ChartContainer>
          <Chart
            chartType='Bar'
            data={DATA}
            height={'100%'}
            loader={<div>Loading Data...</div>}
            width={'100%'}
            options={{
              colors: ['#4dd48f'],
              legend: {position: 'none'},
            }}
          />
        </ChartContainer>
      </Container>
    );
  }
}

export default VisitsChart;
