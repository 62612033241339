import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Photo = styled.img`
  border-radius: 58.8px;
  height: 105px;
  margin-top: 40px;
  width: 105px;
`;

export const Name = styled.p`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  margin: 16px 0;
`;

export const Code = styled.p`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
`;

export const Job = styled.p`
  color: #05c56c;
  font-size: 16px;
  font-weight: bold;
  margin: 16px 0;
`;

export const Where = styled.p`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 30px;
`;
