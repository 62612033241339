import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record, Map } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
  getDataSuccess: ['data'],
  getDataError: ['e'],
  getDetailsRequest: ['id'],
  getDetailsSuccess: ['data', 'products'],
  getDetailsError: ['e'],
  getAgentsRequest: ['region', 'district'],
  getAgentsSuccess: ['data'],
  getAgentsError: ['e'],
  addAssessment: ['date', 'agent', 'visit']
}, {
  prefix: 'VISITS_'
});

const state = new Record({
  data: null,
  details: null,
  products: null,
  visitsCount: null,
  activationsCount: null,
  switchesCount: null,
  visitsCompletedCount: null,
  visitsFailedCount: null,
  agents: null,
});

const INITIAL_STATE = new state({
  data: null,
  details: null,
  products: null,
  visitsCount: 0,
  activationsCount: 0,
  switchesCount: 0,
  visitsCompletedCount: 0,
  visitsFailedCount: 0,
  agents: null,
});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data }) => state
  .set('data', fromJS(data.visits))
  .set('visitsCount', data.visitsCount)
  .set('activationsCount', data.activationsCount)
  .set('switchesCount', data.switchesCount)
  .set('visitsCompletedCount', data.visitsCompletedCount)
  .set('visitsFailedCount', data.visitsFailedCount);

const getDataError = (state = INITIAL_STATE, action) => state;

const getDetailsRequest = (state = INITIAL_STATE, action) => state;

const getDetailsSuccess = (state = INITIAL_STATE, { data, products }) =>
  state.set('details', fromJS(data))
    .set('products', fromJS(products));

const getDetailsError = (state = INITIAL_STATE, action) => state;

const getAgentsRequest = (state = INITIAL_STATE) => state;

const getAgentsSuccess = (state = INITIAL_STATE, { data }) => state.set('agents', fromJS(data));

const getAgentsError = (state = INITIAL_STATE) => state;

const addAssessment = (state = INITIAL_STATE) => state;

const HANDLERS = {
  [Types.GET_DATA_REQUEST]: getDataRequest,
  [Types.GET_DATA_SUCCESS]: getDataSuccess,
  [Types.GET_DATA_ERROR]: getDataError,
  [Types.GET_DETAILS_REQUEST]: getDetailsRequest,
  [Types.GET_DETAILS_SUCCESS]: getDetailsSuccess,
  [Types.GET_DETAILS_ERROR]: getDetailsError,
  [Types.GET_AGENTS_REQUEST]: getAgentsRequest,
  [Types.GET_AGENTS_SUCCESS]: getAgentsSuccess,
  [Types.GET_AGENTS_ERROR]: getAgentsError,
  [Types.ADD_ASSESSMENT]: addAssessment
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
