import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { countSelector } from '../../../modules/root/loading/loading.selectors';
import LoadingBar from './loading-bar.component';

const mapStateToProps = createStructuredSelector({
  count: countSelector
});

export default connect(mapStateToProps)(LoadingBar);
