import {call, cancelled, put, select, takeLatest} from 'redux-saga/effects';
import { Actions as MaterialActions, Types as MaterialTypes } from './material.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors'
import { localFiltersUrlParamsSelector } from './material.selectors'
import { getData } from './material.api';

function* getDataRequest() {
  try {
    const globalFilters = yield select(globalFiltersUrlParamsSelector);
    const localFilters = yield select(localFiltersUrlParamsSelector);
    const filters = `${globalFilters}&${localFilters}`;
    const response = yield call(getData, filters);
    const data = response.data;
    yield put(MaterialActions.getDataSuccess(data));
  } catch (e) {
    yield put(MaterialActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(MaterialActions.getDataError(null));
    }
  }
}

function* actionFilter() {
  yield put(MaterialActions.getDataRequest());
}

export default function* materialSaga() {
  yield takeLatest(MaterialTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(MaterialTypes.ACTION_FILTER, actionFilter);
}
