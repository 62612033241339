export const dataSelector = state => state.get('visits').get('data');

export const visitsCountSelector = state => state.get('visits').get('visitsCount');

export const activationsCountSelector = state => state.get('visits').get('activationsCount');

export const switchesCountSelector = state => state.get('visits').get('switchesCount');

export const visitsCompletedCountSelector = state => state.get('visits').get('visitsCompletedCount');

export const visitsFailedCountSelector = state => state.get('visits').get('visitsFailedCount');

export const detailsSelector = state => state.get('visits').get('details');

export const productsSelector = state => state.get('visits').get('products');

export const agentsSelector = state => state.get('visits').get('agents');
