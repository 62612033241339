import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1);
  display: flex;
  flex-direction: column;
  grid-row: 1 / 3;
`;

export const PinContainer = styled.div`
  height: 59px;
  background-color: rgba(77, 212, 143, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4dd48f;
  font-size: 17.6px;
  font-weight: bold;
`;

export const Pin = styled.div`
  
`;
