import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Actions as TeamActions } from '../../modules/team/team.redux';
import { userSelector, visitsSelector } from '../../modules/team/team.selectors';
import UserProfile from './user-profile.component';

const mapStateToProps = createStructuredSelector({
  data: userSelector,
  visits: visitsSelector
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getDetailsRequest: TeamActions.getDetailsRequest,
  getVisitsRequest: TeamActions.getVisitsRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
