import { PureComponent } from 'react';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';

class LoadingBar extends PureComponent {
  componentWillReceiveProps(nextProps) {
    const { count } = nextProps;
    if (count === 1)
      nprogress.start();
    else if (count === 0)
      nprogress.done();
  }
  componentDidMount() {
    nprogress.configure({ showSpinner: false });
  }
  render() {
    return null;
  }
}

export default LoadingBar;
