import { api } from '../../services/api';

const VISITS_URL = '/visits/';

const ACTIVATIONS_URL = '/activations/';

const PRODUCTS_URL = '/products/';

const AGENTS_URL = '/agents/';

const ROUTING_URL = '/routings/post/';

export const getData = (filters) =>
  api.get(`${VISITS_URL}?${filters}&limit=100`);

export const getVisit = (id) =>
  api.get(`${VISITS_URL}${id}/`);

export const getVisitsCount = (filters) =>
  api.get(`${VISITS_URL}?${filters}&count=true`);

export const getActivationsCount = (filters) =>
  api.get(`${ACTIVATIONS_URL}?${filters}&count=true`);

export const getSwitchesCount = (filters) =>
  api.get(`${ACTIVATIONS_URL}?${filters}&count=true&type=SWITCH`);

export const getVisitsCompletedCount = (filters) =>
  api.get(`${VISITS_URL}?${filters}&count=true&status=COMPLETED`);

export const getVisitsFailedCount = (filters) =>
  api.get(`${VISITS_URL}?${filters}&count=true&status=FAILED`);

export const getProducts = () => api.get(`${PRODUCTS_URL}`);

export const getAgents = (filters) => api.get(`${AGENTS_URL}?role=3E&${filters}`);

export const postRouting = (params) => api.post(`${ROUTING_URL}`, {
  date: params.date,
  agent: params.agent,
  visit: params.visit
});
