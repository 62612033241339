import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1);
  grid-column: 1 / 3;
  width: 100%;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Tr = styled.tr`
  height: 32px;
  :nth-child(even) {
    background-color: rgb(243,243,243, 0.37);
  }
`;

export const Th = styled.th`
  color: #4a4a4a;
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  :first-of-type {
    padding-left: 16px;
  }
`;

export const Td = styled.td`
  color: #4a4a4a;
  font-size: 11px;
  font-weight: normal;
  text-align: left;
  :first-of-type {
    padding-left: 16px;
  }
`;
