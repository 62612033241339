import { api } from '../../services/api';

const TOUCHPOINTS_URL = '/touchpoints/';

const VISITS_URL = '/visits/';

export const getData = () =>
  api.get(`${TOUCHPOINTS_URL}?count=true`);

export const getDetails = (id) =>
  api.get(`${TOUCHPOINTS_URL}${id}/`);

export const getVisits = (id) =>
  api.get(`${VISITS_URL}?touchpoint=${id}`);
