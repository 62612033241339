import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
  getDataSuccess: ['data', 'territories'],
  getDataError: ['e'],
  getDetailsRequest: ['id'],
  getDetailsSuccess: ['data'],
  getDetailsError: ['e'],
  patchTouchpoint: ['id', 'confirmed', 'territory']
}, {
  prefix: 'TOUCHPOINT_VALIDATION_'
});

const state = new Record({
  data: null,
  details: null,
  territories: null,
  count: null
});

const INITIAL_STATE = new state({
  data: null,
  details: null,
  territories: null,
  count: 0
});

const getDataRequest = (state = INITIAL_STATE) => state;

const getDataSuccess = (state = INITIAL_STATE, { data, territories }) =>
  state.set('data', fromJS(data))
    .set('territories', fromJS(territories));

const getDataError = (state = INITIAL_STATE) => state;

const getDetailsRequest = (state = INITIAL_STATE) => state;

const getDetailsSuccess = (state = INITIAL_STATE, { data }) => state.set('details', fromJS(data));

const getDetailsError = (state = INITIAL_STATE) =>
  state
    .set('count', state.get('count') + 1)
    .set('details', null);

const patchTouchpoint = (state = INITIAL_STATE) =>
  state
    .set('count', state.get('count') + 1)
    .set('details', null);

const HANDLERS = {
  [Types.GET_DATA_REQUEST]: getDataRequest,
  [Types.GET_DATA_SUCCESS]: getDataSuccess,
  [Types.GET_DATA_ERROR]: getDataError,
  [Types.GET_DETAILS_REQUEST]: getDetailsRequest,
  [Types.GET_DETAILS_SUCCESS]: getDetailsSuccess,
  [Types.GET_DETAILS_ERROR]: getDetailsError,
  [Types.PATCH_TOUCHPOINT]: patchTouchpoint
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
