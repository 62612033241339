import { api } from '../../services/api';

const PERFORMANCE_MISSIONS_URL = '/performance/missions/';
const PERFORMANCE_ACTIVATIONS_URL = '/performance/activations/';
const PERFORMANCE_PLANOGRAMS_URL = '/performance/planograms/';

export const getMissionsData = (filters) =>
  api.get(`${PERFORMANCE_MISSIONS_URL}?${filters}`);

export const getActivationsData = (filters) =>
  api.get(`${PERFORMANCE_ACTIVATIONS_URL}?${filters}`);

export const getPlanogramsData = (filters) =>
  api.get(`${PERFORMANCE_PLANOGRAMS_URL}?${filters}`);
