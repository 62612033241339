import styled from 'styled-components';
import Icon from '@mdi/react';

export const Container = styled.header`
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1);
  display: flex;
  height: 70px;
`;

export const ButtonContainer = styled.header`
  align-items: center;
  background-color: #ffffff;
  border-right: #f0f0f0 1px solid;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 70px;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonIcon = styled(Icon)`
  
`;

export const Title = styled.h1`
  color: #4a4a4a;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  margin-left: 56px;
`;
