import axios from 'axios';
import { api } from '../../services/api';
import Cookies from 'js-cookie';

const PRODUCTS_URL = '/products/';

export const getProducts = () =>
  api.get(`${PRODUCTS_URL}`);

export const download = (key, filters) => axios({
  url: `dash/export/${key}/?${filters}`,  // https://qa.ireport3.com
  method: 'GET',
  responseType: 'blob',
  headers: localStorage.getItem('token') ? {
    'Authorization': `Token ${localStorage.getItem('token')}`,
    'X-CSRFToken': Cookies.get('csrftoken')
  } : null
});
