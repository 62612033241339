import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
  getDataSuccess: ['data'],
  getDataError: ['e'],
  actionFilter: ['key', 'value']
}, {
  prefix: 'MATERIAL_'
});

const state = new Record({
  data: null,
  filters: null,
  selected: null
});

const INITIAL_STATE = new state({
  data: null,
  filters: fromJS({
    owners: ['PMI', 'SNTA', 'BAT', 'ITG', 'JTI'],
    categories: ['PERMANENT', 'TEMPORARY'],
  }),
  selected: fromJS({
    owners: ['PMI', 'SNTA', 'BAT', 'ITG', 'JTI'],
    categories: ['PERMANENT', 'TEMPORARY'],
  }),
});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data }) => state.set('data', fromJS(data));

const getDataError = (state = INITIAL_STATE, action) => state;

const actionFilter = (state = INITIAL_STATE, { key, value }) => {
  if (state.get('selected').get(key).some(id => id === value)) {
    return state.updateIn(['selected', key], array => {
      let index = array.indexOf(value);
      if (index !== -1) return array.delete(index);
      return array;
    });
  } else {
    return state.updateIn(['selected', key], array => array.push(value));
  }
};

const HANDLERS = {
  [Types.GET_DATA_REQUEST]: getDataRequest,
  [Types.GET_DATA_SUCCESS]: getDataSuccess,
  [Types.GET_DATA_ERROR]: getDataError,
  [Types.ACTION_FILTER]: actionFilter
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
