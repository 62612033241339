import React, { PureComponent } from 'react';
import SimpleLineIcon from 'react-simple-line-icons';
import { Container, Title, Text } from './staff.styles';
import placeholderProfile from '../../../../../images/placeholder-profile.jpeg';
import { UserContainer, UserPhoto, UserInfos, UserName, UserJob } from './staff.styles';

const User = ({ name, job }) => (
  <UserContainer>
    <UserPhoto src={placeholderProfile}/>
    <UserInfos>
      <UserName>{name}</UserName>
      <UserJob>{job}</UserJob>
    </UserInfos>
  </UserContainer>
);

class Staff extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    return (
      <Container>
        <Title>
          <SimpleLineIcon name={'people'} color={'#4a4a4a'} style={{fontSize: '14px', marginRight: '8px'}} />
          <Text>Staff</Text>
        </Title>
        {
          data.map(
            contact =>
              <User name={`${contact.get('first_name')} ${contact.get('last_name')}`} job={(contact.get('type') && contact.get('type').get(0)) ? contact.get('type').get(0).get('name') : null} />
          )
        }
      </Container>
    );
  }
}

export default Staff;
