import {call, cancelled, put, select, takeLatest} from 'redux-saga/effects';
import { Actions as ActivationsActions, Types as ActivationsTypes } from './activations.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors'
import { localFiltersUrlParamsSelector } from './activations.selectors'
import { getData } from './activations.api';

function* getDataRequest() {
  try {
    const globalFilters = yield select(globalFiltersUrlParamsSelector);
    const localFilters = yield select(localFiltersUrlParamsSelector);
    const filters = `${globalFilters}&${localFilters}`;
    const response = yield call(getData, filters);
    const data = response.data;
    yield put(ActivationsActions.getDataSuccess(data));
  } catch (e) {
    yield put(ActivationsActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(ActivationsActions.getDataError(null));
    }
  }
}

function* actionFilter() {
  yield put(ActivationsActions.getDataRequest());
}

export default function* activationsSaga() {
  yield takeLatest(ActivationsTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(ActivationsTypes.ACTION_FILTER, actionFilter);
}
