import React, { PureComponent } from 'react';
import Icon from '@mdi/react';
import Assessment from './assessment';
import Map from './map';
import { mdiMapMarker, mdiClockOutline, mdiAccountCircle, mdiPhone } from '@mdi/js';
import { Container, Header, Title, LineContainer, LineTitle, LineValue } from './visit-details.styles';
import moment from 'moment';

const Line = ({ title, value, icon, color }) => (
  <LineContainer>
    <Icon path={icon} size={'14px'} color={color} />
    <LineTitle>{title}</LineTitle>
    <LineValue>{value}</LineValue>
  </LineContainer>
);

class VisitDetails extends PureComponent {
  render() {
    const { data, agents, addAssessment } = this.props;
    if (!data)
      return null;
    let tp = {
      lat: parseFloat(data.get('touchpoint').get('coordinates').get('latitude')),
      lng: parseFloat(data.get('touchpoint').get('coordinates').get('longitude'))
    };
    let begin = {
      lat: parseFloat(data.get('begin_latitude')),
      lng: parseFloat(data.get('begin_longitude'))
    };
    let end = {
      lat: parseFloat(data.get('end_latitude')),
      lng: parseFloat(data.get('end_longitude'))
    };
    return (
      <Container>
        <Header>
          <Title>Visit details</Title>
          <Assessment visit={data.get('id')} status={data.get('assessment_status')} agents={agents} addAssessment={addAssessment} />
        </Header>
        <br/>
        <Line title={'Begin time:'} value={moment(data.get('begin_time')).format('DD/MM/YYYY - hh:mma')} icon={mdiMapMarker} color={'#4dd48f'} />
        <Line title={'End time:'} value={moment(data.get('end_time')).format('DD/MM/YYYY - hh:mma')} icon={mdiMapMarker} color={'#ff5f73'} />
        <Line title={'Time Spent:'} value={`${data.get('total_minutes')} minutes`} icon={mdiClockOutline} color={'#f5a623'} />
        {
          data.get('contact') &&
          <Line title={'TP Contact:'} value={`${data.get('contact').get('name')} - ${data.get('contact').get('roles').get(0)}`} icon={mdiAccountCircle} color={'#0284fe'} />
        }
        {
          data.get('phones') && data.get('phones').get(0) &&
          <Line title={'Phone:'} value={data.get('phones').get(0)} icon={mdiPhone} color={'#7ed321'} />
        }
      </Container>
    );
  }
}

export default VisitDetails;
