import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-top: 32px;
  width: 100%;
`;

export const Tr = styled.tr`
  height: 35px;
  :nth-child(even) {
    background-color: rgb(243,243,243, 0.37);
  }
  user-select: none;
  :first-of-type {
    pointer-events: none;
  }
`;

export const Th = styled.th`
  color: #4a4a4a;
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  :first-of-type {
    padding-left: 40px;
  }
`;

export const Td = styled.td`
  color: #4a4a4a;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  :first-of-type {
    padding-left: 40px;
  }
`;

