import React, { PureComponent } from 'react';
import Icon from '@mdi/react';
import { mdiSettings } from '@mdi/js';
import { Container, Title, Table, Th, Tr, Td, VisitsListContainer } from './visits-history.styles';
import {Loading} from "../../../modals/user-profile/user-profile.styles";
import moment from 'moment';
import {Link} from "react-router-dom";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css'

const DETAILS_STYLE = {
  color: '#34b0fd',
  fontSize: '10px',
  fontWeight: '500'
};

class VisitsHistory extends PureComponent {
  componentDidMount() {
    const { id, getVisitsRequest } = this.props;
    if (id) {
      getVisitsRequest(id);
    }
  }

  formatTasks(visit) {
    let tasks = visit.get('tasks').filter(
      (value, key) =>
        value === true
    ).keySeq().toArray().map(value =>
      value.replace('_','') + '\n'
    );
    return tasks.toString();
  }

  render() {
    const { data } = this.props;
    if (!data)
      return (
        <Container>
          <Title>Visits List</Title>
        </Container>
      );
    return (
      <Container>
        <Title>Visits List</Title>
        <VisitsListContainer>
          <Table>
            <Tr>
              <Th>Time of visit</Th>
              <Th>TP Name</Th>
              <Th>TP Code</Th>
              <Th>Status</Th>
              <Th>Visit Actions</Th>
            </Tr>
            {
              data.map(
                visit => (
                  <Tr>
                    <Td>{moment(visit.get('begin_time')).format('DD/MM/YYYY - hh:mma')}</Td>
                    <Td>{visit.get('touchpoint_name')}</Td>
                    <Td>{visit.get('touchpoint_old')}</Td>
                    <Td>{visit.get('status')}</Td>
                    <Td>{
                      <Tippy content={() => this.formatTasks(visit)} arrow={true}>
                        <Icon path={mdiSettings} size={'16px'} color={'#4a4a4a'}/>
                      </Tippy>
                    }</Td>
                    <Td>
                      <Link to={{
                        pathname: this.props.match.url,
                        state: {
                          target: 'visit-details',
                          visitId: visit.get('id'),
                          touchPointId: visit.get('touchpoint_id')
                        }
                      }}>DETAILS
                      </Link>
                    </Td>
                  </Tr>
                )
              )
            }
          </Table>
        </VisitsListContainer>
      </Container>
    );
  }
}

export default VisitsHistory;
