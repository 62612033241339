import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Set, Map, Record } from 'immutable';
import moment from 'moment';

export const { Types, Creators } = createActions({
  getFiltersRequest: [],
  getFiltersSuccess: ['filters'],
  getFiltersError: ['e'],
  selectTag: ['filter', 'value'],
  unselectTag: ['filter', 'values'],
  updateRangeTime: ['beginDate', 'endDate'],
  updateSearchInputValue: ['value']
}, {
  prefix: 'GLOBAL_FILTERS_'
});

const state = new Record({
  isLoaded: false,
  isFixe: false,
  filters: null,
  searchInputValue: null,
  selected: null,
  beginDate: null,
  endDate: null
});

const INITIAL_STATE = new state({
  isLoaded: false,
  isFixe: false,
  filters: Map(),
  searchInputValue: '',
  selected: Map(),
  beginDate: moment().subtract(7, 'days'),
  endDate: moment()
});

const getFiltersRequest = (state = INITIAL_STATE) => state;

function formatValue(value) {
  const prefixes = ['R', 'D', 'T'];
  let formattedValue = '';
  switch (typeof value) {
    case 'number':
      formattedValue = `${prefixes[0]}${value}`;
      break;
    case 'object':
      value.forEach((v, i) => {
        formattedValue = `${formattedValue}${prefixes[i]}${v}`;
      });
      break;
    case 'string':
      formattedValue = value;
      break;
    default:
      break;
  }
  return formattedValue;
}

const getFiltersSuccess = (state = INITIAL_STATE, { filters }) => {
  let _filters = Map();
  let _selected = Map();
  let isFixe = false;
  for (const filter in filters) {
    let label = filter.charAt(0).toUpperCase() + filter.slice(1);
    let values = filters[filter].map(value => formatValue(value));
    let _filter = Map();
    _filter = _filter
      .set('id', filter)
      .set('label', label)
      .set('values', Set(values));
    _filters = _filters.set(filter, _filter);
    if (values.length === 1) {
      if (filter === 'district') {
        _selected = _selected.set('region', Set([getValue(values[0], 'region')]));
      }
      if (filter === 'territory') {
        _selected = _selected.set('region', Set([getValue(values[0], 'region')]));
        _selected = _selected.set('region', Set([getValue(values[0], 'district')]));
      }
      _selected = _selected.set(filter, Set([values[0]]));
      isFixe = true
    }
    else
      _selected = _selected.set(filter, Set());
  }
  return state.set('filters', _filters)
    .set('selected', _selected)
    .set('isFixe', isFixe)
    .set('isLoaded', true);
};

const getFiltersError = (state = INITIAL_STATE) => {
  return state;
};

function getValue(value, field) {
  switch (field) {
    case 'region':
      return value.substring(0, 2);
    case 'district':
      return value.substring(0, value.lastIndexOf('T'));
  }
}

const selectTag = (state = INITIAL_STATE, { filter, value }) => {
  if (filter === 'district') {
    return state
      .updateIn(['selected', 'region'], values => values.add(getValue(value, 'region')))
      .updateIn(['selected', filter], values => values.add(value));
  }
  if (filter === 'territory') {
    return state
      .updateIn(['selected', 'region'], values => values.add(getValue(value, 'region')))
      .updateIn(['selected', 'district'], values => values.add(getValue(value, 'district')))
      .updateIn(['selected', filter], values => values.add(value));

  }
  return state.updateIn(['selected', filter], values => values.add(value));
};

const unselectTag = (state = INITIAL_STATE, { filter, values }) => {
  return state.set('selected', Map({
    'region': Set([]),
    'district': Set([]),
    'territory': Set([]),
    'segment': Set([])
  }));
};

const updateRangeTime = (state = INITIAL_STATE, { beginDate, endDate }) =>
  state.set('beginDate', moment(beginDate))
    .set('endDate', moment(endDate));

const updateSearchInputValue = (state = INITIAL_STATE, { value }) => state.set('searchInputValue', value);

const HANDLERS = {
  [Types.GET_FILTERS_REQUEST]: getFiltersRequest,
  [Types.GET_FILTERS_SUCCESS]: getFiltersSuccess,
  [Types.GET_FILTERS_ERROR]: getFiltersError,
  [Types.SELECT_TAG]: selectTag,
  [Types.UNSELECT_TAG]: unselectTag,
  [Types.UPDATE_RANGE_TIME]: updateRangeTime,
  [Types.UPDATE_SEARCH_INPUT_VALUE]: updateSearchInputValue
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
