import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
  getDataSuccess: ['data'],
  getDataError: ['e']
}, {
  prefix: 'DASHBOARD_'
});

const state = new Record({
  data: null
});

const INITIAL_STATE = new state({
  data: null
});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data }) => state.set('data', fromJS(data));

const getDataError = (state = INITIAL_STATE, action) => state;

const HANDLERS = {
  [Types.GET_DATA_REQUEST]: getDataRequest,
  [Types.GET_DATA_SUCCESS]: getDataSuccess,
  [Types.GET_DATA_ERROR]: getDataError
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
