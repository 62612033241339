import React, { PureComponent } from 'react';
import { mdiChevronLeft } from '@mdi/js';
import { Container, ButtonContainer, ButtonIcon, Title } from './modal-bar.styles';

class ModalBar extends PureComponent {
  render() {
    return (
      <Container>
        <ButtonContainer onClick={() => this.props.history.goBack()}>
          <ButtonIcon path={mdiChevronLeft} color={'#4a4a4a'} size={'32px'} />
        </ButtonContainer>
        <Title>Visit details</Title>
      </Container>
    );
  }
}

export default ModalBar;
