import React, { PureComponent } from 'react';
import ModalBar from '../../components/visit-details/modal-bar';
import TouchPointDetails from '../../components/visit-details/touch-point-details';
import VisitsDetails from '../../components/visit-details/visit-details';
import Material from '../../components/visit-details/material';
import Planogram from '../../components/visit-details/planogram';
import Merchandising from '../../components/visit-details/merchandising';
import Activations from '../../components/visit-details/activations';
import { Container } from './visit-details.styles';

class _VisitsDetails extends PureComponent {
  componentDidMount() {
    const { getDetailsRequest, id } = this.props;
    getDetailsRequest(id);
  }
  render() {
    const { data, products, agents, addAssessment } = this.props;
    if (!data)
      return null;
    return (
      <>
        <ModalBar {...this.props} />
        <Container>
          <TouchPointDetails data={data.get('visit').get('touchpoint')}/>
          <VisitsDetails data={data.get('visit')} agents={agents} addAssessment={addAssessment}/>
          <Material data={data.get('visit').get('equipmentreport')}/>
          <Planogram data={data.get('visit').get('planogram_set')}/>
          <Merchandising data={data.get('visit').get('kpi_set')} products={products} />
          <Activations data={data.get('visit').get('activation_set')} />
        </Container>
      </>
    );
  }
}

export default _VisitsDetails;
