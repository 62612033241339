import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 106px);
`;

export const StatContainer = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const Label = styled.p`
  color: #4a4a4a;
  font-size: 9.7px;
  font-weight: bold;
  margin: 0 0 4px;
`;

export const Value = styled.p`
  color: #4a4a4a;
  font-size: 25.9px;
  font-weight: normal;
  margin: 0;
`;
