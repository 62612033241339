import React, { PureComponent } from 'react';
import Icon from '@mdi/react';
import { mdiClockOutline } from '@mdi/js';
import moment from 'moment';
import { Container, Title, List, LineContainer, LineText, LineIcon } from './user-login-history.styles';

const Line = ({ text }) => (
  <LineContainer>
    <LineIcon><Icon path={mdiClockOutline} size={'12px'} color={'#4a4a4a'} /></LineIcon>
    <LineText>{ text }</LineText>
  </LineContainer>
);

class UserLoginHistory extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Container>
        <Title>Login History</Title>
        <List>
          {
            data.toArray().reverse().map(
              login =>
                <Line text={moment(login.get('created_at')).format('DD/MM/YYYY - hh:mma')}/>
            )
          }
        </List>
      </Container>
    );
  }
}

export default UserLoginHistory;
