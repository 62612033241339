import React, { PureComponent } from 'react';
import SimpleLineIcon from 'react-simple-line-icons';
import { Container, Title, Text } from './touch-point.styles';
import { LineContainer, LineTitle, LineValue } from './touch-point.styles';

const Line = ({ title, value }) => {
  if (!value)
    return null;
  return (
    <LineContainer>
      <LineTitle>{title}</LineTitle>
      <LineValue>{value}</LineValue>
    </LineContainer>
  );
};

const formatDays = (days) => {
  let daysString = '';
  if (!days)
    return daysString;
  days.map(
    day => daysString = daysString + day.get('name').toTitleCase() + ', '
  );
  if (daysString.endsWith(', '))
    daysString = daysString.slice(0, -2);
  return daysString;
};

class TouchPoint extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    return (
      <Container>
        <Title>
          <SimpleLineIcon name={'home'} color={'#4a4a4a'} style={{fontSize: '14px', marginRight: '8px'}} />
          <Text>Touch Point</Text>
        </Title>
        <Line title={'Type de magasin:'} value={data.get('store_type') ? data.get('store_type').get('name').toTitleCase() : null} />
        <Line title={'Situation du locale:'} value={data.get('store_status') ? data.get('store_status').get('name').toTitleCase() : null} />
        <Line title={'Superficie m2:'} value={data.get('store_area') ? data.get('store_area').get('name') : null} />
        <Line title={'Jours d’overture:'} value={formatDays(data.get('opening_days'))} />
        <Line title={'Horaires de travail:'} value={`Overture: ${data.get('starting_work_hour')} - Fermeture: ${data.get('ending_work_hour')}`} />
        <Line title={'Meilleur jour de vente:'} value={formatDays(data.get('best_selling_days'))} />
      </Container>
    );
  }
}

export default TouchPoint;
