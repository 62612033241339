import { api } from '../../services/api';

const MISSIONS_URL = '/missions/preview/';

const AGENTS_URL = '/agents/';

const TOUCH_POINTS_URL = '/touchpoints/';

const ROUTING_URL = '/routings/post/';

const TRADE_PROGRAMS_URL = '/trade/programs/';

const INVOLVEMENTS_URL = '/involvements/';

export const getMissions = (filters) => api.get(`${MISSIONS_URL}?${filters}`);

export const getAgents = (filters) => api.get(`${AGENTS_URL}?${filters}`);

export const getTouchPoints = (filters) => api.get(`${TOUCH_POINTS_URL}?${filters}`);

export const postRouting = (params) => api.post(`${ROUTING_URL}`, {
    date: params.date,
    agent: params.agent,
    touchpoints: params.touchpoints,
    tasks: params.tasks
});

export const deleteMission = id => api.post(`${ROUTING_URL}`, {
    deleted: [
        id
    ]
});

export const getTradePrograms = () => api.get(TRADE_PROGRAMS_URL);

export const getInvolvements = () => api.get(INVOLVEMENTS_URL);