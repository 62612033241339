import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import { configureStore } from '../modules/store';
import ReactModal from 'react-modal';
import LoadingBar from '../components/root/loading-bar';
import LoadingRoute from '../components/root/loading-route';
import ModalBar from '../components/root/modal-bar';
import VisitsDetails from '../modals/visit-details';
import UserProfile from '../modals/user-profile';
import TouchPoint from '../modals/touchpoint';
import requireAuthentication from '../hocs/require-authentication';

const Authentication = Loadable({
  loader: () => import('./authentication'),
  loading: LoadingRoute
});

const App = Loadable({
  loader: () => import('./app'),
  loading: LoadingRoute
});

const store = configureStore();

const Root = () => (
  <Provider store={store}>
    <Router>
      <>
        <LoadingBar/>
        <Switch>
          <Route path={'/authentication'} component={Authentication} />
          <Route path={'/'} component={requireAuthentication(App)} />
        </Switch>
        <Route path='' component={requireAuthentication(withRouter(Modal))} />
      </>
    </Router>
  </Provider>
);

const Modal = (props) => {
  if (!props.location.state)
    return null;
  const { target, id } = props.location.state;
  return (
    <ReactModal
      isOpen={true}
      style={{
        overlay: {
          backgroundColor: 'rgba(55, 55, 55, 0.67)',
          zIndex: 9999
        },
        content: {
          top: '50px',
          left: '50px',
          right: '50px',
          bottom: '50px',
          backgroundColor: '#f3f3f3',
          borderRadius: '5px',
          boxShadow: '0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1)',
          padding: '0px'
        }
      }}
    >
      {
        target === 'visit-details' && <VisitsDetails {...props} id={id}/>
      }
      {
        target === 'user-profile' && <UserProfile {...props} id={id}/>
      }
      {
        target === 'touchpoint-details' && <TouchPoint {...props} id={id} />
      }
    </ReactModal>
  );
};

String.prototype.toTitleCase = function() {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
    'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'),
      function(txt) {
        return txt.toLowerCase();
      });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ['Id', 'Tv'];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'),
      uppers[i].toUpperCase());

  return str;
};

export default Root;
