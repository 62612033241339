import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { Actions as DashboardActions, Types as DashboardTypes } from './dashboard.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors'
import { getData } from './dashboard.api';

function* getDataRequest() {
  try {
    const filters = yield select(globalFiltersUrlParamsSelector);
    const response = yield call(getData, filters);
    const data = response.data;
    yield put(DashboardActions.getDataSuccess(data));
  } catch (e) {
    yield put(DashboardActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(DashboardActions.getDataError(null));
    }
  }
}

export default function* dashboardSaga() {
  yield takeLatest(DashboardTypes.GET_DATA_REQUEST, getDataRequest);
}
