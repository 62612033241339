import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
  getDataSuccess: ['data'],
  getDataError: ['e'],
  selectFilter: ['value'],
  unselectFilter: ['value']
}, {
  prefix: 'PERFORMANCE_'
});

const state = new Record({
  data: null,
  filters: null,
  selected: null
});

const INITIAL_STATE = new state({
  data: null,
  filters: fromJS(['SFF', 'CFF', 'BBE', 'TRE']),
  selected: fromJS(['SFF', 'CFF', 'BBE', 'TRE'])
});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data }) => state.set('data', fromJS(data));

const getDataError = (state = INITIAL_STATE, action) => state;

const selectFilter = (state = INITIAL_STATE, { value }) => state.updateIn(['selected'], array => array.push(value));

const unselectFilter = (state = INITIAL_STATE, { value }) =>
  state.updateIn(['selected'], array => {
    let index = array.indexOf(value);
    if (index !== -1) return array.delete(index);
    return array;
  });

const HANDLERS = {
  [Types.GET_DATA_REQUEST]: getDataRequest,
  [Types.GET_DATA_SUCCESS]: getDataSuccess,
  [Types.GET_DATA_ERROR]: getDataError,
  [Types.SELECT_FILTER]: selectFilter,
  [Types.UNSELECT_FILTER]: unselectFilter
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
