import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects';
import { Actions as TeamActions, Types as TeamTypes } from './team.redux';
import { globalFiltersUrlParamsSelector } from '../root/global-filters/global-filters.selectors';
import { getData, getUser, getVisits } from './team.api';

function* getDataRequest() {
  try {
    const globalFilters = yield select(globalFiltersUrlParamsSelector);
    const response = yield call(getData, globalFilters);
    const data = response.data;
    yield put(TeamActions.getDataSuccess(data));
  } catch (e) {
    yield put(TeamActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(TeamActions.getDataError(null));
    }
  }
}

function* getDetailsRequest({ id }) {
  try {
    const response = yield call(getUser, id);
    const data = response.data;
    yield put(TeamActions.getDetailsSuccess(data));
  } catch (e) {
    yield put(TeamActions.getDetailsError(e));
  } finally {
    if (yield cancelled()) {
      yield put(TeamActions.getDetailsError(null));
    }
  }
}

function* getVisitsRequest({ id }) {
  try {
    const response = yield call(getVisits, id);
    const data = response.data;
    yield put(TeamActions.getVisitsSuccess(data));
  } catch (e) {
    yield put(TeamActions.getVisitsError(e));
  } finally {
    if (yield cancelled()) {
      yield put(TeamActions.getVisitsError(null));
    }
  }
}

export default function* teamSaga() {
  yield takeLatest(TeamTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(TeamTypes.GET_DETAILS_REQUEST, getDetailsRequest);
  yield takeLatest(TeamTypes.GET_VISITS_REQUEST, getVisitsRequest);
}
