import {all, cancelled, call, put, select, takeLatest} from 'redux-saga/effects';
import { Actions as VisitsActions, Types as VisitsTypes } from './visits.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors'
import { getData, getVisitsCount, getVisitsCompletedCount, 
  getVisitsFailedCount, getActivationsCount, getVisit,
   getSwitchesCount, getProducts, getAgents, postRouting } from './visits.api';

function* getDataRequest() {
  try {
    const filters = yield select(globalFiltersUrlParamsSelector);
    const [visits, visitsCount, activationsCount, switchesCount, visitsCompletedCount, visitsFailedCount] = yield all([
      call(getData, filters),
      call(getVisitsCount, filters),
      call(getActivationsCount, filters),
      call(getSwitchesCount, filters),
      call(getVisitsCompletedCount, filters),
      call(getVisitsFailedCount, filters),
    ]);
    const data = {
      visits: visits.data,
      visitsCount: visitsCount.data,
      activationsCount: activationsCount.data,
      switchesCount: switchesCount.data,
      visitsCompletedCount: visitsCompletedCount.data,
      visitsFailedCount: visitsFailedCount.data
    };
    yield put(VisitsActions.getDataSuccess(data));
  } catch (e) {
    yield put(VisitsActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(VisitsActions.getDataError(null));
    }
  }
}

function* getDetailsRequest({ id }) {
  try {
    const [ data, products ] =  yield all([
      call(getVisit, id),
      call(getProducts)
    ]);
    yield put(VisitsActions.getDetailsSuccess(data.data, products.data));
  } catch (e) {
    yield put(VisitsActions.getDetailsError(e));
  } finally {
    if (yield cancelled()) {
      yield put(VisitsActions.getDataError(null));
    }
  }
}

function* getDetailsSuccess({ data }) {
  const region = data.visit.touchpoint.region;
  const district = data.visit.touchpoint.district;
  yield put(VisitsActions.getAgentsRequest(region, district));
}

function* getAgentsRequest({ region, district }) {
  try {
    const filters = `region=${region}&district=${district}`;
    const response = yield call(getAgents, filters);
    const data = response.data;
    yield put(VisitsActions.getAgentsSuccess(data));
  } catch (e) {
    yield put(VisitsActions.getAgentsError(e));
  } finally {
    if (yield cancelled()) {
      yield put(VisitsActions.getAgentsError(null));
    }
  }
}

function* addAssessment({ date, agent, visit }) {
  try {
    const params = {
      date,
      agent,
      visit
    };
    yield call(postRouting, params);
  } catch (e) {

  }
}

export default function* visitsSaga() {
  yield takeLatest(VisitsTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(VisitsTypes.GET_DETAILS_REQUEST, getDetailsRequest);
  yield takeLatest(VisitsTypes.GET_DETAILS_SUCCESS, getDetailsSuccess);
  yield takeLatest(VisitsTypes.GET_AGENTS_REQUEST, getAgentsRequest);
  yield takeLatest(VisitsTypes.ADD_ASSESSMENT, addAssessment);
}
