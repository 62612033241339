import { put, takeEvery } from 'redux-saga/effects';
import { Actions as LoadingActions } from './loading.redux';

function* start() {
  yield put(LoadingActions.start());
}

function* end() {
  yield put(LoadingActions.end());
}

const startPattern = ({ type }) => type.endsWith('REQUEST') || type.endsWith('SELECT_AGENT');

const endPattern = ({ type }) => type.endsWith('SUCCESS') || type.endsWith('ERROR');

export default function* loadingSaga() {
  yield takeEvery(startPattern, start);
  yield takeEvery(endPattern, end);
}
