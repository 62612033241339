import React, { PureComponent } from 'react';
import Header from '../../../components/visit-details/root/header';
import { Container, Table, Tr, Th, Td } from './activations.styles';
import moment from 'moment';

const ACTIVATION_TYPES = {
  1: 'Switch',
  2: 'Attempt',
  3: 'Loyalty'
};

class Activations extends PureComponent {
  renderTbody() {
    let lignes = [];
    for (let i = 0; i < 10; i++) {
      let ligne = <Tr>
        <Td>12/05/2018 - 10:22am</Td>
        <Td>SWITCH</Td>
        <Td>RIM</Td>
        <Td>Marlboro Red</Td>
        <Td>Lighter</Td>
        <Td>DETAILS</Td>
      </Tr>;
      lignes.push(ligne);
    }
    return lignes;
  }
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    if (data.isEmpty())
      return null;
    return (
      <Container>
        <Header title={'Activations'} beginTime={'11:15am'} endTime={'11:45am'} beginTimeColor={'#4dd48f'} endTimeColor={'#4a4a4a'} />
        <Table>
          <Tr>
            <Th>Time of activation</Th>
            <Th>Activation Type</Th>
            <Th>Switched from</Th>
            <Th>Switched to</Th>
            <Th>Gift</Th>
          </Tr>
          {
            data.map(
              activation => (
                <Tr>
                  <Td>{moment(activation.get('timestamp')).format('DD/MM/YYYY - hh:mma')}</Td>
                  <Td>{ACTIVATION_TYPES[activation.get('type')]}</Td>
                  <Td>{activation.get('using_products')}</Td>
                  <Td>{activation.get('exposed_to_product')}</Td>
                </Tr>
              )
            )
          }
        </Table>
      </Container>
    );
  }
}

export default Activations;
