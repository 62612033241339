import React, { PureComponent } from 'react';
import UserInfos from '../../components/user-profile/user-infos';
import VisitsChart from '../../components/user-profile/visits-chart';
import VisitsHistory from '../../components/user-profile/visits-history';
import { Container, Loading } from './user-profile.styles';

class UserProfile extends PureComponent {
  componentDidMount() {
    const { getDetailsRequest, id } = this.props;
    getDetailsRequest(id);
  }
  render() {
    const { data, visits, getVisitsRequest } = this.props;
    if (!data)
      return <Loading />;
    return (
      <Container>
        <UserInfos data={data.get('agent')}/>
        {/*<VisitsChart/>*/}
        <VisitsHistory {...this.props} data={visits} id={data.get('agent').get('user').get('username')} getVisitsRequest={getVisitsRequest}/>
      </Container>
    );
  }
}

export default UserProfile;
