import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
  getDataSuccess: ['data'],
  getDataError: ['e'],
  getDetailsRequest: ['id'],
  getDetailsSuccess: ['data', 'visits'],
  getDetailsError: ['e']
}, {
  prefix: 'TOUCHPOINTS_'
});

const state = new Record({
  data: null,
  details: null,
  visits: null
});

const INITIAL_STATE = new state({
  data: null,
  details: null,
  visits: null
});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data }) => state.set('data', fromJS(data));

const getDataError = (state = INITIAL_STATE, action) => state;

const getDetailsRequest = (state = INITIAL_STATE, action) => state;

const getDetailsSuccess = (state = INITIAL_STATE, { data, visits }) =>
  state.set('details', fromJS(data))
    .set('visits', fromJS(visits));

const getDetailsError = (state = INITIAL_STATE, action) => state;

const HANDLERS = {
  [Types.GET_DATA_REQUEST]: getDataRequest,
  [Types.GET_DATA_SUCCESS]: getDataSuccess,
  [Types.GET_DATA_ERROR]: getDataError,
  [Types.GET_DETAILS_REQUEST]: getDetailsRequest,
  [Types.GET_DETAILS_SUCCESS]: getDetailsSuccess,
  [Types.GET_DETAILS_ERROR]: getDetailsError
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
