import { api } from '../../services/api';

const TEAM_URL = '/fieldforce/';

const AGENTS_URL = '/agents/';

const VISITS = '/visits/';

export const getData = (filters) =>
  api.get(`${TEAM_URL}?${filters}`);

export const getUser = (id) =>
  api.get(`${AGENTS_URL}${id}/`);

export const getVisits = (id) =>
  api.get(`${VISITS}?agent=${id}`);
