import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0 24px 16px;
`;

export const Content = styled.div`
  display: flex;
`;

export const LinesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 33px);
  margin: 0 16px;
  width: 100%;
`;

export const LineTitle = styled.p`
  color: #333333;
  font-size: 13px;
  font-weight: bold;
  margin: 0;
`;

export const LineValue = styled.p`
  color: #4a4a4a;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
`;

export const LinePercent = styled.p`
  color: #06d26f;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  text-align: end;
`;

export const Picture = styled.img`
  height: 149px;
  margin: 0 5px 22px 16px;
  object-fit: contain;
  width: 96px;
`;
