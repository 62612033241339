import React, { PureComponent } from 'react';
import TouchPointGeneral from './touch-point-general';
import TouchPointStats from './touch-point-stats';
import TouchPointHistory from './touch-point-history';
import { Container } from './touch-point-infos.styles';

class TouchPointInfos extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Container>
        <TouchPointGeneral data={data} />
        <TouchPointStats data={data.get('stats')} />
        <TouchPointHistory data={data.get('last_visit')} />
      </Container>
    );
  }
}

export default TouchPointInfos;
