import React, { PureComponent } from 'react';
import Icon from '@mdi/react';
import { mdiClockOutline } from '@mdi/js';
import { Container, Title, List, LineContainer, LineText, LineIcon } from './touch-point-history.styles';
import moment from 'moment';

const Line = ({ text }) => (
  <LineContainer>
    <LineIcon><Icon path={mdiClockOutline} size={'12px'} color={'#4a4a4a'} /></LineIcon>
    <LineText>{ text }</LineText>
  </LineContainer>
);

class TouchPointHistory extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;

    return (
      <Container>
        <Title>Last visit:</Title>
        <List>
          <Line text={`${moment(data.get('time')).format('DD/MM/YYYY - hh:mma')} - ${data.get('username')} - ${data.get('role')}`}/>
        </List>
      </Container>
    );
  }
}

export default TouchPointHistory;
