import React, { PureComponent } from 'react';
import ModalBar from '../../components/touchpoint/modal-bar';
import TouchPointInfos from '../../components/touchpoint/touch-point-infos';
import TouchPointTabs from '../../components/touchpoint/touch-point-tabs';
import TouchPointDetails from '../../components/touchpoint/touch-point-tabs/touch-point-details';
import Material from '../../components/touchpoint/touch-point-tabs/material';
import Visits from '../../components/touchpoint/touch-point-tabs/visits';
import { Container } from './touchpoint.styles';

const TABS = [
  'TP Details',
  'Material',
  'Visits History'
];

class Touchpoint extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };
    this.setActiveTab = this.setActiveTab.bind(this);
  }
  setActiveTab(index) {
    this.setState({
      activeTab: index
    });
  }
  componentDidMount() {
    const { getDetailsRequest, id } = this.props;
    getDetailsRequest(id);
  }
  render() {
    const { data, visits } = this.props;
    if (!data)
      return null;
    const { activeTab } = this.state;
    return (
      <>
        <ModalBar {...this.props} />
        <Container>
        <TouchPointInfos data={data.get('touch_point')} />
        <TouchPointTabs labels={TABS} activeTab={activeTab} setActiveTab={this.setActiveTab}>
          {
            activeTab === 0 && <TouchPointDetails data={data.get('touch_point')}/>
          }
          {
            activeTab === 1 && <Material data={data.get('touch_point').get('equipment_set')} />
          }
          {
            activeTab === 2 && <Visits data={visits} />
          }
        </TouchPointTabs>
      </Container>
      </>
    );
  }
}

export default Touchpoint;
