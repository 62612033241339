import { createActions, createReducer } from 'reduxsauce';
import { Record } from 'immutable';

export const { Types, Creators } = createActions({
  start: [],
  end: [],
}, {
  prefix: 'LOADING_'
});

const state = new Record({
  count: 0
});

const INITIAL_STATE = new state({
  count: 0
});

const start = (state = INITIAL_STATE) => state.set('count', state.get('count') + 1);

const end = (state = INITIAL_STATE) => state.set('count', state.get('count') - 1);

const HANDLERS = {
  [Types.START]: start,
  [Types.END]: end
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
