import { PureComponent } from 'react';

class LoadingRoute extends PureComponent {
  /*componentWillReceiveProps(nextProps) {
    const { end } = this.props;
    const { isLoading } = nextProps;
    if (!isLoading)
      end();
  }
  componentDidMount() {
    const { isLoading, start } = this.props;
    if (isLoading)
      start();
  }
  componentWillUnmount() {
    const { end } = this.props;
    end();
  }*/
  render() {
    return null;
  }
}

export default LoadingRoute;
