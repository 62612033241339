import styled from 'styled-components';

export const PendingText = styled.h1`
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
`;

export const AssessedText = styled.h1`
  color: #00b2ff;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
`;

export const PlanText = styled.h1`
  border: 1px solid #00b2ff;
  color: #00b2ff;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 9px 16px;
  text-transform: uppercase;
  user-select: none;
  :hover {
    cursor: pointer;
  };
`;

export const Container = styled.div`
  background-color: #fff;
  border: 2px solid #00b2ff;
  display: flex;
  flex-direction: column;
  height: 450px;
  padding: 0 16px;
  position: absolute;
  right: 0;
  top: 55px;
  width: 90%;
  z-index: 999999;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 220px;
  overflow-y: auto;
  width: 100%;
`;

export const SubTitle = styled.h2`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  margin: 16px 0;
`;

export const Buttons = styled.div`
  background-color: red;
  display: flex;
  height: 86px;
  margin-top: 24px;
  width: 100%;
`;

export const UserContainer = styled.div`
  align-items: center;
  border-bottom: #e9e9e9 solid 1px;
  display: flex;
  height: 46px;
  min-height: 46px;
  justify-content: space-between;
  user-select: none;
  :hover {
    cursor: pointer;
  };
`;

export const UserContainer2 = styled.div`
  align-items: center;
  border-bottom: #e9e9e9 solid 1px;
  display: flex;
  height: 46px;
`;

export const UserInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.p`
  color: #4a4a4a;
  font-size: 12px;
  font-weight: normal;
  margin: 0 0 2px 0;
`;

export const UserAddress = styled.p`
  color: #8f8f8f;
  font-size: 10px;
  font-weight: normal;
  margin: 0;
`;

export const UserPhoto = styled.img`
  border-radius: 50%;
  height: 32px;
  margin-right: 8px;
`;

export const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: ${props => props.disabled ? '#c3c3c3' : '#00b2ff'};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  width: 138px;
  height: 48px;
  text-transform: uppercase;
  user-select: none;
  position: absolute;
  bottom: 16px;
  right: 16px;
  :hover {
    cursor: pointer;
  };
`;

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a4a4a4;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border: solid 1px #c3c3c3;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  width: 138px;
  height: 48px;
  user-select: none;
  position: absolute;
  bottom: 16px;
  left: 16px;
  :hover {
    cursor: pointer;
  };
`;
