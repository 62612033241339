import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { detailsSelector, visitsSelector } from '../../modules/touchpoints/touchpoints.selectors';
import { Actions as TouchpointActions } from '../../modules/touchpoints/touchpoints.redux';
import Touchpoint from './touchpoint.component';

const mapStateToProps = createStructuredSelector({
  data: detailsSelector,
  visits: visitsSelector
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getDetailsRequest: TouchpointActions.getDetailsRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Touchpoint);
