import React, { PureComponent } from 'react';
import Icon from '@mdi/react';
import planogramIcon from '../../../../images/planogram-icon.svg';
import { mdiClipboardCheckOutline, mdiAccountCheck, mdiAccountConvert } from '@mdi/js';
import { Container, StatContainer, InfosContainer, Label, Value } from './touch-point-stats.styles';

const Stat = ({ label, value, icon }) => (
  <StatContainer>
    {
      label === 'Planogram Corrected' ?
        <img src={planogramIcon} height={'14px'} /> :
        <Icon path={icon} color={'#c0c0d4'} size={'32px'} />
    }
    <InfosContainer>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </InfosContainer>
  </StatContainer>
);

class TouchPointStats extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    return (
      <Container>
        <Stat label={'Completed Visits'} value={data.get('completed_visits')} icon={mdiClipboardCheckOutline} />
        <Stat label={'Planogram Corrected'} value={data.get('conform_planograms')} icon={mdiClipboardCheckOutline} />
        <Stat label={'LAS Activations'} value={data.get('activations')} icon={mdiAccountCheck} />
        <Stat label={'Switches'} value={data.get('switches')} icon={mdiAccountConvert} />
      </Container>
    );
  }
}

export default TouchPointStats;
