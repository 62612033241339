import React, { PureComponent } from 'react';
import TouchPoint from './touch-point';
import Localisation from './localisation';
import Staff from './staff';
import Approvisionnement from './approvisionnement';
import { Container } from './touch-point-details.styles';

class TouchPointDetails extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Container>
        <TouchPoint data={data.get('retailer_profile')} />
        <Localisation data={data} />
        <Staff data={data.get('contact_set')} />
        <Approvisionnement data={data.get('retailer_profile')} />
      </Container>
    );
  }
}

export default TouchPointDetails;
