import React, { PureComponent } from 'react';
import placeholderProfile from '../../../../images/placeholder-profile.jpeg';
import { Container, Photo, Name, Code, Job, Where } from './user-profile.styles';

class UserProfile extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Container>
        <Photo src={placeholderProfile}/>
        <Name>
          {
            data.get('first_name') + ' ' + data.get('last_name')
          }
        </Name>
        <Code>
          {
            data.get('user').get('username')
          }
        </Code>
        <Job>
          {
            data.get('user').get('role').get('secondary')
          }
        </Job>
        <Where>
          {
            data.get('user').get('username')
          }
        </Where>
      </Container>
    );
  }
}

export default UserProfile;
