import { createSelector } from 'reselect';

const routingSelector = state => state.get('routingInjection');

export const dataSelector = createSelector(
    routingSelector, state => state.get('data')
);

export const agentsSelector = createSelector(
    routingSelector, state => state.get('agents')
);

export const filtersSelector = createSelector(
    routingSelector, state => state.get('filters')
);

export const touchPointsSelector = createSelector(
    routingSelector, state => state.get('touchPoints')
);

export const touchPointsSelectionSelector = createSelector(
    routingSelector, state => state.get('touchPointsSelection')
);

export const agentSelector = createSelector(
    routingSelector, state => state.get('agent')
);

export const selectionSelector = createSelector(
    routingSelector, state => state.get('selection')
);

export const onlyLampSelector = createSelector(
    routingSelector, state => state.get('onlyLamp')
);

export const onlyWholesalerSelector = createSelector(
    routingSelector, state => state.get('onlyWholesaler')
);

export const DSDSelector = createSelector(
    routingSelector, state => state.get('filters').get('pos_type')
);

export const staem_distibSelector = createSelector(
    routingSelector, state => state.get('staem_distib')
);

export const localFiltersUrlParamsSelector = createSelector(
    routingSelector, state => {
        let urlParams = '';
        let filters = state.get('filters');

        let role = filters.get('role');

        urlParams += 'role=' + role;

        return urlParams;
    });

export const dateSelector = createSelector(
    filtersSelector, state => state.get('date')
);

export const selectedAgentSelector = createSelector(
    agentSelector, state => state.get('id')
);

export const selectedAgentUsernameSelector = createSelector(
    agentSelector, state => state.get('username')
);

export const selectedTouchPointsSelector = createSelector(
    selectionSelector, state => state.get('touchPoints')
);

export const selectedTasksSelector = createSelector(
    selectionSelector, state => state.get('tasks').map(taskId => ({
        type_id: taskId,
        is_optional: state.get('optionalTasks').some(id => id === taskId)
    }))
);

export const isLoadingSelector = createSelector(
    routingSelector, state => state.get('isLoading')
);

export const errorSelector = createSelector(
  routingSelector, state => state.get('error')
);

export const tradeProgramsSelector = createSelector(
    routingSelector, state => state.get('tradePrograms')
);

export const involvementsSelector = createSelector(
    routingSelector, state => state.get('involvements')
);