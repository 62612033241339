import React, { PureComponent } from 'react';
import placeholderProfile from '../../../../images/placeholder-profile.jpeg';
import { PendingText, AssessedText, PlanText, Container, SubTitle, SaveButton, CancelButton, ListContainer } from './assessment.styles';
import { UserContainer, UserContainer2, UserPhoto, UserInfos, UserName, UserAddress } from './assessment.styles';
import Icon from '@mdi/react';
import { mdiCheckboxBlankCircleOutline, mdiCheckCircle } from '@mdi/js';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const User = ({ name, address, selected, onClick }) => (
  <UserContainer onClick={onClick}>
    <UserContainer2>
      <UserPhoto src={placeholderProfile}/>
      <UserInfos>
        <UserName>{name}</UserName>
        <UserAddress>{address}</UserAddress>
      </UserInfos>
    </UserContainer2>
    {
      selected &&
      <Icon path={mdiCheckCircle} size={'20px'} color={'#0284fe'} />
    }
    {
      !selected &&
      <Icon path={mdiCheckboxBlankCircleOutline} size={'20px'} color={'#0284fe'} />
    }
  </UserContainer>
);

class Plan extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      agent: null,
      date: new Date()
    };
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }
  handleSaveClick() {
    const { save, visit } = this.props;
    const { agent, date } = this.state;
    if (agent && date) {
      save(
        moment(date).format('YYYY-MM-DD'),
        agent,
        visit
      );
    }
  }
  render() {
    const { agents, cancel } = this.props;
    const { agent, date } = this.state;
    return (
      <Container>
        <SubTitle>1. Select 3E member</SubTitle>
        <ListContainer>
          {
            agents &&
            agents.map(
              user =>
              <User name={`${user.get('full_name')}`} address={`${user.get('username')}`}
               selected={user.get('id') === agent} onClick={() => this.setState({agent: user.get('id')})} />
            )
          }
        </ListContainer>
        <SubTitle>2. Select Visit date</SubTitle>
        <DatePicker
          selected={date}
          onChange={date => this.setState({date})}
          dateFormat='dd/MM/yyyy'
          style={{width: '100%'}} />
        <CancelButton onClick={cancel}>Cancel</CancelButton>
        <SaveButton disabled={!agent || !date} onClick={this.handleSaveClick}>Save Plan</SaveButton>
      </Container>
    );
  }
}

class Assessment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  render() {
    const { visit, status, agents, addAssessment } = this.props;
    const { open } = this.state;
    switch (status) {
      case 'PLANNED':
        return <PendingText>Pending Assessment</PendingText>;
      case 'COMPLETED':
      case 'FAILED':
        return <AssessedText>Visit Assessed</AssessedText>;
      case null:
        return (
          <>
            <PlanText onClick={() => this.setState({open:true})}>Plan Assessment Visit</PlanText>
            {
              open &&
              <Plan visit={visit} cancel={() => this.setState({open: false})} agents={agents}
               save={(date, agent, visit) => {
                this.setState({open: false});
                addAssessment(date, agent, visit);
               }}/>
            }
          </>
        );
      default:
        return null;
    }
  }
}

export default Assessment;
