import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiSettings } from '@mdi/js';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css'
import { Container, Table, Tr, Th, Td } from './visits.styles';

class Visits extends PureComponent {
  formatTasks(visit) {
    let tasks = visit.get('tasks').filter(
      (value, key) =>
        value === true
    ).keySeq().toArray().map(value =>
      value.replace('_','') + '\n'
    );
    return tasks.toString();
  }
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    return (
      <Container>
        <Table>
          <Tr>
            <Th>Time of visit</Th>
            <Th>Full name</Th>
            <Th>Code</Th>
            <Th>Team</Th>
            <Th>Visit Actions</Th>
          </Tr>
          {
            data.map(
              visit =>
                <Tr>
                  <Td>{moment(visit.get('begin_time')).format('DD/MM/YYYY - hh:mma')}</Td>
                  <Td>{visit.get('staff_name')}</Td>
                  <Td>{visit.get('staff')}</Td>
                  <Td>{visit.get('role')}</Td>
                  <Td>{
                    <Tippy content={() => this.formatTasks(visit)} arrow={true}>
                      <Icon path={mdiSettings} size={'16px'} color={'#4a4a4a'}/>
                    </Tippy>
                  }</Td>
                  <Td>
                    <Link to={{
                      pathname: this.props.match.url,
                      state: {
                        target: 'visit-details',
                        id: visit.get('id')
                      }
                    }}>DETAILS
                    </Link>
                  </Td>
                </Tr>
            )
          }
        </Table>
      </Container>
    );
  }
}

export default withRouter(Visits);
