import {call, cancelled, put, select, takeLatest} from 'redux-saga/effects';
import { Actions as PlanogramsActions, Types as PlanogramsTypes } from './planograms.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors';
import { localFiltersUrlParamsSelector } from './planograms.selectors';
import { getData } from './planograms.api';

function* getDataRequest() {
  try {
    const globalFilters = yield select(globalFiltersUrlParamsSelector);
    const localFilters = yield select(localFiltersUrlParamsSelector);
    const filters = `${globalFilters}&${localFilters}`;
    const response = yield call(getData, filters);
    const data = response.data;
    yield put(PlanogramsActions.getDataSuccess(data));
  } catch (e) {
    yield put(PlanogramsActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(PlanogramsActions.getDataError(null));
    }
  }
}

function* actionFilter() {
  yield put(PlanogramsActions.getDataRequest());
}

export default function* planogramsSaga() {
  yield takeLatest(PlanogramsTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(PlanogramsTypes.ACTION_FILTER, actionFilter);
}
