import { all } from 'redux-saga/effects';
import achievementsSaga from './achievements/achievements.saga';
import activationsSaga from './activations/activations.saga';
import authenticationSaga from './authentication/authentication.saga';
import dashboardSaga from './dashboard/dashboard.saga';
import exportSaga from './export/export.saga';
import globalFiltersSaga from './root/global-filters/global-filters.saga';
import loadingSaga from './root/loading/loading.saga';
import materialSaga from './material/material.saga';
import merchandisingSaga from './merchandising/merchandising.saga';
import performanceSaga from './performance/performance.saga';
import planogramsSaga from './planograms/planograms.saga';
import routingSaga from './routing/routing.saga';
import routingInjectionSaga from './routing-injection/routing-injection.saga';
import teamSaga from './team/team.saga';
import touchpointsSaga from './touchpoints/touchpoints.saga';
import touchpointValidationSaga from './touchpoint-validation/touchpoint-validation.saga';
import visitsSaga from './visits/visits.saga';

export default function* rootSaga() {
  yield all([
    achievementsSaga(),
    activationsSaga(),
    authenticationSaga(),
    dashboardSaga(),
    exportSaga(),
    globalFiltersSaga(),
    loadingSaga(),
    materialSaga(),
    merchandisingSaga(),
    performanceSaga(),
    planogramsSaga(),
    routingSaga(),
    routingInjectionSaga(),
    teamSaga(),
    touchpointsSaga(),
    touchpointValidationSaga(),
    visitsSaga()
  ]);
}
