import { call, cancelled, put, takeLatest, select, all } from 'redux-saga/effects';
import { RoutingTypes, RoutingActions } from './routing.redux';
import { getMissions, getAgents, getTouchPoints, postRouting, deleteMission, getTradePrograms, getInvolvements } from './routing.api';
import {globalFiltersRoutingUrlParamsSelector} from '../../modules/root/global-filters/global-filters.selectors';
import {selectedAgentUsernameSelector, localFiltersUrlParamsSelector, touchPointsSelector} from './routing.selectors';
import { dateSelector, selectedAgentSelector, selectedTouchPointsSelector, selectedTasksSelector } from './routing.selectors';

function* getDataRequest() {
    try {
        const globalFilters = yield select(globalFiltersRoutingUrlParamsSelector);
        const localFilters = yield select(localFiltersUrlParamsSelector);
        const [data, agents, tradePrograms, involvements] = yield all([
            call(getMissions, `${globalFilters}`),
            call(getAgents, `${localFilters}`),
            call(getTradePrograms),
            call(getInvolvements)
            //call(getTouchPoints, `${globalFilters}`)
        ]);
        yield put(RoutingActions.getDataSuccess(data.data, agents.data, tradePrograms.data.programs, involvements.data));
    } catch (e) {
        yield put(RoutingActions.getDataError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingActions.getDataError(null));
        }
    }
}

function* selectAgent({ region, district, territory }) {

    try {
        let filters = `region=${region}`;
        if (district)
            filters += `&district=${district}`;
        if (territory)
            filters += `&territory=${territory}`;

        const date = yield select(dateSelector);
        const agent = yield select(selectedAgentUsernameSelector);

        const [response1, response2] = yield all([
            call(getTouchPoints, filters),
            call(getMissions, `date=${date}&agent=${agent}`),
        ]);

        const data1 = response1.data;
        const data2 = response2.data;

        yield put(RoutingActions.getTouchPointsSuccess(data1, data2));
    } catch (e) {
        yield put(RoutingActions.getTouchPointsError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingActions.getTouchPointsError(null));
        }
    }
}

function* addMission() {
    try {
        const date = yield select(dateSelector);
        const agent = yield select(selectedAgentSelector);
        const touchpoints = yield select(selectedTouchPointsSelector);
        const tasks = yield select(selectedTasksSelector);
        let params = {
            date, agent, touchpoints, tasks
        };
        const response = yield call(postRouting, params);
        const data = response.data;
        yield put(RoutingActions.addMissionSuccess(data));
    } catch (e) {
        yield put(RoutingActions.addMissionError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingActions.addMissionError(null));
        }
    }
}

function* deleteMissionRequest({ id }) {
    try {
        const response = yield call(deleteMission, id);
        const data = response.data;
        yield put(RoutingActions.deleteMissionSuccess());
    } catch (e) {
        yield put(RoutingActions.deleteMissionError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingActions.deleteMissionError(null));
        }
    }
}

export default function* routingSaga() {
    yield takeLatest(RoutingTypes.GET_DATA_REQUEST, getDataRequest);
    yield takeLatest(RoutingTypes.SELECT_AGENT, selectAgent);
    yield takeLatest(RoutingTypes.ADD_MISSION_REQUEST, addMission);
    yield takeLatest(RoutingTypes.DELETE_MISSION_REQUEST, deleteMissionRequest);
    yield takeLatest(RoutingTypes.ACTION_FILTER, getDataRequest);

}