import React, { PureComponent } from 'react';
import { Container, TabContainer, Label, TabContent, TabsContainer } from './touch-point-tabs.styles';

const Tab = ({ label, active, onClick }) => (
  <TabContainer active={active} onClick={onClick}>
    <Label active={active}>{label}</Label>
  </TabContainer>
);

class Tabs extends PureComponent {
  onTabClick(index) {
    this.props.setActiveTab(index);
  }
  render() {
    const { labels, activeTab, children } = this.props;
    return (
      <Container>
        <TabsContainer>
          {
            labels.map(
              (label, index) =>
                <Tab key={index} label={label} active={index === activeTab} onClick={() => this.onTabClick(index)} />
            )
          }
        </TabsContainer>
        <TabContent>
          {
            children
          }
        </TabContent>
      </Container>
    );
  }
}

export default Tabs;
