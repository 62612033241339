import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%
`;

export const TabsContainer = styled.div`
  align-items: center;
  border-bottom: #e5e5e5 solid 0.5px;
  display: flex;
  height: 66px;
  padding: 0 32px;
  width: 100%;
`;

export const TabContainer = styled.div`
  align-items: center;
  border-bottom: ${props => props.active ? '#0284fe solid 3px' : 'none'};
  color: ${props => props.active ? '#0284fe' : '#4a4a4a'};
  display: flex;
  height: 100%;
  margin-right: 90px;
  user-select: none;
  :hover {
    border-bottom: #0284fe solid 3px;
    color: #0284fe;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const Label = styled.p`
  font-size: 15px;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  margin: 0;
`;

export const TabContent = styled.div`
  
`;
