import { createSelector } from 'reselect';

export const dataSelector = state => state.get('planograms').get('data');

export const filtersSelector = state => state.get('planograms').get('filters');

export const selectedSelector = state => state.get('planograms').get('selected');

export const localFiltersUrlParamsSelector = createSelector(
  selectedSelector,
  (selected) => {
    let urlParams = '';

    let owners = selected;
    if (!owners.isEmpty()) {
      urlParams += 'owner=';
      owners.map(
        owner =>
          urlParams += owner + ','
      );
      urlParams = urlParams.slice(0, -1);
    }

    return urlParams;
  }
);
