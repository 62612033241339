import { createSelector } from 'reselect';

export const dataSelector = state => state.get('material').get('data');

export const filtersSelector = state => state.get('material').get('filters');

export const selectedSelector = state => state.get('material').get('selected');

const SUBCATEGORIES = {
  PERMANENT: ['BACKWALL', 'COUNTER', 'OVERHEAD', 'FLOOR UNIT'],
  TEMPORARY: ['DANGLER', 'WOBBLER', 'POSTER', 'CASH TRAY', 'SHELF TALKER', 'COUNTER TOP', 'JUMBO BOX']
};

export const localFiltersUrlParamsSelector = createSelector(
  selectedSelector,
  (selected) => {
    let urlParams = '';

    let owners = selected.get('owners');
    if (!owners.isEmpty()) {
      urlParams += 'owner=';
      owners.map(
        owner =>
          urlParams += owner + ','
      );
      urlParams = urlParams.slice(0, -1);
      urlParams += '&'
    }

    let categories = selected.get('categories');
    if (!categories.isEmpty()) {
      urlParams += 'subcategory=';
      categories.map(
        category => {
          SUBCATEGORIES[category].map(
            subcategory =>
              urlParams += subcategory + ','
          )
        }
      );
      urlParams = urlParams.slice(0, -1);
    }

    return urlParams;
  }
);
