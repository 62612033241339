import {all, call, cancelled, put, takeLatest} from 'redux-saga/effects';
import { Actions as TouchpointsActions, Types as TouchpointsTypes } from './touchpoints.redux';
import { getData, getDetails, getVisits } from './touchpoints.api';

function* getDataRequest() {
  try {
    const response = yield call(getData);
    const data = response.data;
    yield put(TouchpointsActions.getDataSuccess(data));
  } catch (e) {
    yield put(TouchpointsActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(TouchpointsActions.getDataError(null));
    }
  }
}

function* getDetailsRequest({ id }) {
  try {
    const [data, visits] = yield all([
      call(getDetails, id),
      call(getVisits, id)
    ]);
    yield put(TouchpointsActions.getDetailsSuccess(data.data, visits.data));
  } catch (e) {
    yield put(TouchpointsActions.getDetailsError(e));
  } finally {
    if (yield cancelled()) {
      yield put(TouchpointsActions.getDataError(null));
    }
  }
}

export default function* touchpointsSaga() {
  yield takeLatest(TouchpointsTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(TouchpointsTypes.GET_DETAILS_REQUEST, getDetailsRequest);
}
