import React, { PureComponent } from 'react';
import Header from '../../../components/visit-details/root/header';
import Card from '../../../components/visit-details/merchandising/card';
import { Container, Grid } from './merchandising.styles';

class Merchandising extends PureComponent {
  getProductInfos(id) {
    let result = {
      name : '',
      image : ''
    };
    if (!this.props.products)
      return result;
    let product = this.props.products.get('products').findLast(product => product.get('id') === id);
    result.name = product.get('name');
    result.image = product.get('image');
    return result;
  }
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    if (data.isEmpty())
      return null;
    return (
      <Container>
        <Header title={'Merchandising'} beginTime={'10:55am'} endTime={'11:15am'} beginTimeColor={'#ff5f73'} endTimeColor={'#4dd48f'} />
        <Grid>
          {
            data.map(
              product =>
                <Card product={this.getProductInfos(product.get('product')).name}
                      image={this.getProductInfos(product.get('product')).image}
                      handling={product.get('handling')}
                      availability={product.get('availability')}
                      buyPrice={product.get('buy_price')}
                      price={product.get('price')}
                      stocks={product.get('stocks')}
                />
            )
          }
        </Grid>
      </Container>
    );
  }
}

export default Merchandising;
