import React, { PureComponent } from 'react';
import PmiLogo from '../../../../../images/tmo_logos/pmi_logo.svg';
import batLogo from '../../../../../images/tmo_logos/bat_logo.svg';
import itgLogo from '../../../../../images/tmo_logos/itg_logo.svg';
import sntaLogo from '../../../../../images/tmo_logos/snta_logo.svg';
import jtiLogo from '../../../../../images/tmo_logos/jti_logo.svg';
import { Container, Photo, FooterContainer, FooterTitle, FooterText, LogoContainer,
  HeaderContainer, Logo, Infos, Title1, Title2, Count } from './card.styles';

const TMO = [{},
  {
    name: 'Philip Morris',
    logo: PmiLogo
  },
  {
    name: 'British American Tobacco',
    logo: batLogo
  },
  {
    name: 'Japan Imperial Tobacco',
    logo: jtiLogo
  },
  {
    name: 'Imperial Tobacco',
    logo: itgLogo
  },
  {
    name: 'SNTA',
    logo: sntaLogo
  }
];

const Header = ({ tmo, name1, count }) => (
  <HeaderContainer>
    <LogoContainer>
      <Logo src={TMO[tmo].logo} />
      <Infos>
        <Title1>{name1}</Title1>
        <Title2>{TMO[tmo].name}</Title2>
      </Infos>
    </LogoContainer>
    <Count>{count}</Count>
  </HeaderContainer>
);

const Footer = ({ text }) => (
  <FooterContainer>
    <FooterTitle>Communication:</FooterTitle>
    <FooterText>{text}</FooterText>
  </FooterContainer>
);

class Card extends PureComponent {
  render() {
    const { tmo, name1, name2, count, image } = this.props;
    return (
      <Container>
        <Header tmo={tmo} name1={name1} count={count} />
        <Photo src={image}/>
        <Footer text={name2} />
      </Container>
    );
  }
}

export default Card;
