import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding: 24px;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 32px;
`;

export const Text = styled.p`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
`;

export const LineContainer = styled.div`
  display: flex;
  margin-top: 16px;
  &:last-of-type {
     margin-bottom: 45px;
  }
`;

export const LineTitle = styled.p`
  color: #a4a4a4;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
`;

export const LineValue = styled.p`
  color: #555555;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 0 20px;
`;
