import React, { PureComponent } from 'react';
import tp from '../../../../images/tp.png';
import { Container, Photo, Name } from './touch-point-general.styles';
import { LineContainer, LineTitle, LineValue } from './touch-point-general.styles';

const Line = ({ title, value }) => {
  if (!value)
    return null;
  return (
    <LineContainer>
      <LineTitle>{title}</LineTitle>
      <LineValue>{value}</LineValue>
    </LineContainer>
  );
};

class TouchPointGeneral extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Container>
        <Photo src={data.get('photo')}/>
        <Name>{data.get('display_name')}</Name>
        <Line title={'POS Type:'} value={data.get('type')} />
        <Line title={'POS Code:'} value={data.get('human_id')} />
        <Line title={'POS Segment:'} value={data.get('segmentation')} />
        <Line title={'Registre de Commerce:'} value={data.get('registre_de_commerce')} />
        <Line title={'Location: '} value={data.get('commune')+', '+data.get('wilaya')} />
        {/*<Line title={'Phone:'} value={'0552474047'} />*/}
      </Container>
    );
  }
}

export default TouchPointGeneral;
