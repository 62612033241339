import { createSelector } from 'reselect';
import { Map } from 'immutable';
import moment from 'moment';

const filtersSelector = state => state.get('globalFilters').get('filters');

const selectedSelector = state => state.get('globalFilters').get('selected');

export const searchInputValueSelector = state => state.get('globalFilters').get('searchInputValue');

export const selectedFiltersSelector = createSelector(
  filtersSelector,
  selectedSelector,
  (filters, selected) =>
    selected.map(
      (values, id) =>
        Map({
          id: id,
          label: filters.get(id) ? filters.get(id).get('label') : id,
          values: values
        })
    )
);

function getValue(value, field) {
  switch (field) {
    case 'region':
      return value.substring(0, 2);
    case 'district':
      return value.substring(0, value.lastIndexOf('T'));
  }
}

function toShow(selected, id, value) {

  if (selected.get(id).contains(value))
    return false;

  if (id === 'segment')
    return true;

  if (id === 'region') {
    return (
      selected.get('district').isEmpty() && selected.get('territory').isEmpty()
    )
  }

  if (id === 'district')
    return (
      selected.get('region').size < 2 && selected.get('territory').isEmpty()
      &&
      (
        selected.get('region').isEmpty()
        ||
        (!selected.get('region').isEmpty() && selected.get('region').contains(getValue(value, 'region')))
      )
    );

  if (id === 'territory')
    return (
      (selected.get('region').size < 2 && selected.get('district').size < 2)
      &&
      (
        (selected.get('district').isEmpty() && selected.get('region').isEmpty())
        ||
        (selected.get('district').size === 1 && selected.get('district').toList().get(0) === getValue(value, 'district'))
        ||
        (selected.get('district').isEmpty() && selected.get('region').size === 1 && selected.get('region').toList().get(0) === getValue(value, 'region'))
      )
    );
}
export const unselectedFiltersSelector = createSelector(
  filtersSelector,
  selectedSelector,
  (filters, selected) =>
    filters.map(
      filter =>
        Map({
          id: filter.get('id'),
          label: filter.get('label'),
          values: filter.get('values').filter(value => toShow(selected, filter.get('id'), value))
        })
    )
);

export const suggestionsSelector = createSelector(
  selectedFiltersSelector,
  unselectedFiltersSelector,
  searchInputValueSelector,
  (selectedFilters, unselectedFilters, searchInputValue) => {
    const filterText = searchInputValue.toLowerCase();
    let types = unselectedFilters
      .toList()
      .filter(
        filter =>
          filter.get('label').toLowerCase().search(filterText) > -1 && !filter.get('values').isEmpty()
      ).map(
        filter =>
          Map({
            id: filter.get('id'),
            label: filter.get('label'),
            values: filter.get('values')
          })
      );
    let values = unselectedFilters
      .toList()
      .map(
        filter =>
          Map({
            id: filter.get('id'),
            label: filter.get('label'),
            values: filter.get('values').filter(value => value.toLowerCase().search(filterText) > -1)
          })
      ).filter(
        filter =>
          !filter.get('values').isEmpty()
      );
    return {
      types: types.toJS(),
      values: values.toJS()
    };
  }
);

export const beginDateSelector = state => state.get('globalFilters').get('beginDate');

export const endDateSelector = state => state.get('globalFilters').get('endDate');

export const isGlobalFiltersLoadedSelector = state => state.get('globalFilters').get('isLoaded');

export const isFixeSelector = state => state.get('globalFilters').get('isFixe');

export const globalFiltersUrlParamsSelector = createSelector(
  selectedFiltersSelector,
  beginDateSelector,
  endDateSelector,
  (globalFilters, beginDate, endDate) => {
    let urlParams = '';

    urlParams += `begin_time=${moment(beginDate).format('YYYY-MM-DD')}&end_time=${moment(endDate).format('YYYY-MM-DD')}`;

    let regionFilter = '&region=';
    globalFilters.get('region').get('values').map(
      value =>
        regionFilter += value.substring(1) + ','
    );
    if (regionFilter.endsWith(',')) {
      regionFilter = regionFilter.slice(0, -1);
      regionFilter += '&';
    }
    if (regionFilter.endsWith('&region='))
      regionFilter = '';

    let districtFilter = 'district=';
    globalFilters.get('district').get('values').map(
      value =>
        districtFilter += value.substring(3) + ','
    );
    if (districtFilter.endsWith(',')) {
      districtFilter = districtFilter.slice(0, -1);
      districtFilter += '&';
    }
    if (districtFilter.endsWith('district='))
      districtFilter = '';

    let territoryFilter = 'territory=';
    globalFilters.get('territory').get('values').map(
      value =>
        territoryFilter += value.substring(value.lastIndexOf('T') + 1) + ','
    );
    if (territoryFilter.endsWith(',')) {
      territoryFilter = territoryFilter.slice(0, -1);
      territoryFilter += '&';
    }
    if (territoryFilter.endsWith('territory='))
      territoryFilter = '';

    let segmentFilter = 'segment=';
    globalFilters.get('segment').get('values').map(
      value =>
        segmentFilter += value + ','
    );
    if (segmentFilter.endsWith(',')) {
      segmentFilter = segmentFilter.slice(0, -1);
      segmentFilter += '&';
    }
    if (segmentFilter.endsWith('segment='))
      segmentFilter = '';

    urlParams += regionFilter + districtFilter + territoryFilter + segmentFilter;

    if (urlParams.endsWith('&'))
      urlParams = urlParams.slice(0, -1);

    return urlParams;
  }
);

export const globalFiltersWithoutDatesUrlParamsSelector = createSelector(
  selectedFiltersSelector,
  (globalFilters) => {
    let urlParams = '';

    let regionFilter = 'region=';
    globalFilters.get('region').get('values').map(
      value =>
        regionFilter += value.substring(1) + ','
    );
    if (regionFilter.endsWith(',')) {
      regionFilter = regionFilter.slice(0, -1);
      regionFilter += '&';
    }
    if (regionFilter.endsWith('region='))
      regionFilter = '';

    let districtFilter = 'district=';
    globalFilters.get('district').get('values').map(
      value =>
        districtFilter += value.substring(3) + ','
    );
    if (districtFilter.endsWith(',')) {
      districtFilter = districtFilter.slice(0, -1);
      districtFilter += '&';
    }
    if (districtFilter.endsWith('district='))
      districtFilter = '';

    let territoryFilter = 'territory=';
    globalFilters.get('territory').get('values').map(
      value =>
        territoryFilter += value.substring(value.lastIndexOf('T') + 1) + ','
    );
    if (territoryFilter.endsWith(',')) {
      territoryFilter = territoryFilter.slice(0, -1);
      territoryFilter += '&';
    }
    if (territoryFilter.endsWith('territory='))
      territoryFilter = '';

    let segmentFilter = 'segment=';
    globalFilters.get('segment').get('values').map(
      value =>
        segmentFilter += value + ','
    );
    if (segmentFilter.endsWith(',')) {
      segmentFilter = segmentFilter.slice(0, -1);
      segmentFilter += '&';
    }
    if (segmentFilter.endsWith('segment='))
      segmentFilter = '';

    urlParams += regionFilter + districtFilter + territoryFilter + segmentFilter;

    if (urlParams.endsWith('&'))
      urlParams = urlParams.slice(0, -1);

    return urlParams;
  }
);

export const globalFiltersRoutingUrlParamsSelector = createSelector(
  selectedFiltersSelector,
  (globalFilters) => {
    let urlParams = '';

    urlParams += `begin_time=${moment().format('YYYY-MM-DD')}&end_time=${moment().add(7, 'days').format('YYYY-MM-DD')}`;

    let regionFilter = '&region=';
    globalFilters.get('region').get('values').map(
      value =>
        regionFilter += value.substring(1) + ','
    );
    if (regionFilter.endsWith(',')) {
      regionFilter = regionFilter.slice(0, -1);
      regionFilter += '&';
    }
    if (regionFilter.endsWith('&region='))
      regionFilter = '';

    let districtFilter = 'district=';
    globalFilters.get('district').get('values').map(
      value =>
        districtFilter += value.substring(3) + ','
    );
    if (districtFilter.endsWith(',')) {
      districtFilter = districtFilter.slice(0, -1);
      districtFilter += '&';
    }
    if (districtFilter.endsWith('district='))
      districtFilter = '';

    let territoryFilter = 'territory=';
    globalFilters.get('territory').get('values').map(
      value =>
        territoryFilter += value.substring(value.lastIndexOf('T') + 1) + ','
    );
    if (territoryFilter.endsWith(',')) {
      territoryFilter = territoryFilter.slice(0, -1);
      territoryFilter += '&';
    }
    if (territoryFilter.endsWith('territory='))
      territoryFilter = '';

    let segmentFilter = 'segment=';
    globalFilters.get('segment').get('values').map(
      value =>
        segmentFilter += value + ','
    );
    if (segmentFilter.endsWith(',')) {
      segmentFilter = segmentFilter.slice(0, -1);
      segmentFilter += '&';
    }
    if (segmentFilter.endsWith('segment='))
      segmentFilter = '';

    urlParams += regionFilter + districtFilter + territoryFilter + segmentFilter;

    if (urlParams.endsWith('&'))
      urlParams = urlParams.slice(0, -1);

    return urlParams;
  }
);
