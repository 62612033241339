import { all, call, cancelled, put, takeLatest, select } from 'redux-saga/effects';
import { Actions as PerformanceActions, Types as PerformanceTypes } from './performance.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors';
import { localFiltersUrlParamsSelector } from './performance.selectors';
import { getMissionsData, getActivationsData, getPlanogramsData } from './performance.api';

function* getDataRequest() {
  try {
    const globalFilters = yield select(globalFiltersUrlParamsSelector);
    const localFilters = yield select(localFiltersUrlParamsSelector);
    const filters = `${globalFilters}&${localFilters}`;

    const [missions, activations, planograms] = yield all([
      call(getMissionsData, filters),
      call(getActivationsData, filters),
      call(getPlanogramsData, filters)
    ]);
    const data = {
      missions: missions.data,
      activations: activations.data,
      planograms: planograms.data
    };
    yield put(PerformanceActions.getDataSuccess(data));
  } catch (e) {
    yield put(PerformanceActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(PerformanceActions.getDataError(null));
    }
  }
}

function* selectFilter() {
  yield put(PerformanceActions.getDataRequest());
}

function* unselectFilter() {
  yield put(PerformanceActions.getDataRequest());
}

export default function* performanceSaga() {
  yield takeLatest(PerformanceTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(PerformanceTypes.SELECT_FILTER, selectFilter);
  yield takeLatest(PerformanceTypes.UNSELECT_FILTER, unselectFilter);
}
