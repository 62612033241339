import React, { PureComponent } from 'react';
import Icon from '@mdi/react';
import planogramIcon from '../../../../images/planogram-icon.svg';
import { mdiClipboardCheckOutline, mdiAccountCheck, mdiAccountConvert } from '@mdi/js';
import { Container, StatContainer, InfosContainer, Label, Value } from './user-stats.styles';

const Stat = ({ label, value, icon }) => (
  <StatContainer>
    {
      label === 'Planogram Corrected' ?
        <img src={planogramIcon} height={'14px'} /> :
        <Icon path={icon} color={'#c0c0d4'} size={'32px'} />
    }
    <InfosContainer>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </InfosContainer>
  </StatContainer>
);

class UserStats extends PureComponent {
  render() {
    return (
      <Container>
        <Stat label={'Completed Visits'} value={'1580'} icon={mdiClipboardCheckOutline} />
        <Stat label={'Planogram Corrected'} value={'523'} icon={mdiClipboardCheckOutline} />
        <Stat label={'LAS Activations'} value={'730'} icon={mdiAccountCheck} />
        <Stat label={'Switches'} value={'360'} icon={mdiAccountConvert} />
      </Container>
    );
  }
}

export default UserStats;
