import React, { PureComponent } from 'react';
import Header from '../../../components/visit-details/root/header';
import Card from './card';
import permanentPosMaterialIcon from '../../../images/permanent-pos-material-icon.png';
import { Container, TitleContainer, Image, Text } from './planogram.styles';
import {Grid} from "../material/material.styles";
import temporaryPosMaterialIcon from "../../../images/temporary-pos-material-icon.png";

const Title = ({ icon, value }) => (
  <TitleContainer>
    <Image src={icon} />
    <Text>{value}</Text>
  </TitleContainer>
);

class Planogram extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    if (data.isEmpty())
      return null;

    const permanentMaterial = data.filter(
      equipment => equipment.get('equipment').get('category').get('type') === 'PERMANANT MATERIAL'
    );
    const temporaryMaterial = data.filter(
      equipment => equipment.get('equipment').get('type') === 'TEMPORARY MATERIAL'
    );
    return (
      <Container>
        <Header title={'Planogram'} beginTime={'10:40am'} endTime={'10:55am'} beginTimeColor={'#0284fe'} endTimeColor={'#ff5f73'} />
        <Title icon={permanentPosMaterialIcon} value={'Permanent POS Material'}/>
        <Grid>
          {
            permanentMaterial.map(
              pm => pm.get('equipment').get('owner') &&
                <Card tmo={pm.get('equipment').get('owner')} name1={pm.get('equipment').get('category').get('subtype')} name2={pm.get('equipment').get('category').get('subtype')} count={pm.get('equipment').get('quantity')} image={pm.get('image')}
                isConform={pm.get('is_conform')} isCorrected={pm.get('is_corrected')} isHotZone={pm.get('zone_chaude')}/>
            )
          }
        </Grid>
      </Container>
    );
  }
}

export default Planogram;
