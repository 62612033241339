import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Photo = styled.img`
  height: 245px;
  width: 100%;
`;

export const Name = styled.p`
  border-bottom: #e5e5e5 solid 0.5px;
  color: #555555;
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 32px;
  padding: 15.5px 0;
  text-align: center;
`;

export const LineContainer = styled.div`
  display: flex;
  margin-left: 24px;
  margin-bottom: 20px;
  &:last-of-type {
     margin-bottom: 45px;
  }
`;

export const LineTitle = styled.p`
  color: #a4a4a4;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
`;

export const LineValue = styled.p`
  color: #555555;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 0 20px;
`;
