import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1);
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
`;

export const Title = styled.h1`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 24px;
`;

export const Image = styled.img`
  object-fit: cover;
  margin-bottom: 24px;
  height: 249px;
`;

export const LineContainer = styled.div`
  display: grid;
  grid-template-columns: 91px 1fr;
  margin-left: 24px;
  margin-bottom: 20px;
`;

export const LineTitle = styled.p`
  color: #a4a4a4;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
`;

export const LineValue = styled.p`
  color: #555555;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
`;
