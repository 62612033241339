import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 379px 1fr;
  grid-template-rows: 1fr;
  grid-gap: 24px;
  height: calc(100% - 70px);
  padding: 24px;
  overflow-y: auto;
`;
