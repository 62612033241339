import { call, put, takeLatest } from 'redux-saga/effects';
import { Actions as AuthenticationActions, Types as AuthenticationTypes } from './authentication.redux';
import { authenticate, verify, logout } from './authentication.api';
import { BrowserRouter } from 'react-router-dom'

function* authenticateRequest({ username, password }) {
  try {
    const response = yield call(authenticate, username, password);
    const { token } = response.data;
    yield put(AuthenticationActions.authenticateSuccess(token, username));
  } catch (e) {
    yield put(AuthenticationActions.authenticateError(e));
  }
}

function* verifyRequest({ auth_request, pin }) {
  try {
    const response = yield call(verify, auth_request, pin);
    const { token, username } = response.data;
    yield put(AuthenticationActions.verifySuccess(token, username));
  } catch (e) {
    yield put(AuthenticationActions.verifyError(e));
  }
}

function* logoutRequest() {
  try {
    yield call(logout);
    yield put(AuthenticationActions.logoutSuccess());
  } catch (e) {
    yield put(AuthenticationActions.logoutError(e));
  }
}

function* logoutSuccess() {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  window.location.href = '/';
}

function* logoutError() {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  window.location.href = '/';
}

function* failure(action) {
  if (action.e)
    if (action.e.response)
      if (action.e.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        window.location.href = '/';
      }
}

export default function* globalFiltersSaga() {
  yield takeLatest(AuthenticationTypes.AUTHENTICATE_REQUEST, authenticateRequest);
  yield takeLatest(AuthenticationTypes.VERIFY_REQUEST, verifyRequest);
  yield takeLatest(AuthenticationTypes.LOGOUT_REQUEST, logoutRequest);
  yield takeLatest(AuthenticationTypes.LOGOUT_SUCCESS, logoutSuccess);
  yield takeLatest(AuthenticationTypes.LOGOUT_ERROR, logoutError);
  yield takeLatest(action => action.type.endsWith('ERROR'), failure);
}