import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: #f3f3f3 solid 1px;
  display: flex;
  height: 66.9px;
`;

export const Title = styled.h1`
  align-self: center;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding-left: 24px;
`;

export const TimeContainer = styled.div`
  align-items: center;
  border-right: #f3f3f3 solid 1px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  width: 116.1px;
`;

export const PointContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.p`
  color: #4a4a4a;
  font-size: 9.8px;
  font-weight: normal;
  margin: 6.3px 0 0 0;
`;

export const DottedLine= styled.hr`
  position: absolute;
  top: 23px;
  left: 33.5px;
  border:none;
  border-top:2px dotted #cecece;
  height:2px;
  margin: 0;
  width:47px;
`;