import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1);
  grid-row: 1 / 3;
  max-height: 100%;
  overflow-y: auto;
`;

export const Title = styled.h1`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  margin: 24px 0 40px 24px;
`;

export const VisitsListContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Tr = styled.tr`
  height: 35px;
  :nth-child(even) {
    background-color: rgb(243,243,243, 0.37);
  }
`;

export const Th = styled.th`
  color: #4a4a4a;
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  :first-of-type {
    padding-left: 16px;
  }
`;

export const Td = styled.td`
  color: #4a4a4a;
  font-size: 11px;
  font-weight: normal;
  text-align: left;
  :first-of-type {
    padding-left: 16px;
  }
`;
