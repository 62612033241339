import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { detailsSelector, productsSelector, agentsSelector } from '../../modules/visits/visits.selectors';
import { Actions as VisitsActions } from '../../modules/visits/visits.redux';
import VisitsDetails from './visit-details.component';

const mapStateToProps = createStructuredSelector({
  data: detailsSelector,
  products: productsSelector,
  agents: agentsSelector
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getDetailsRequest: VisitsActions.getDetailsRequest,
  addAssessment: VisitsActions.addAssessment
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VisitsDetails);
