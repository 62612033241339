import styled, {keyframes} from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 397px 1fr;
  grid-template-rows: 286px 1fr;
  grid-gap: 24px;
  height: calc(100% - 70px);
  padding: 24px;
  overflow-y: auto;
`;

const rotateplane = keyframes`
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
} 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
} 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
}
`;

export const Loading = styled.div`
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 250px auto;
  -webkit-animation: ${rotateplane} 1.2s infinite ease-in-out;
  animation: ${rotateplane} 1.2s infinite ease-in-out;
`;

