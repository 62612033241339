import {call, cancelled, put, select, takeLatest} from 'redux-saga/effects';
import { Actions as MerchandisingActions, Types as MerchandisingTypes } from './merchandising.redux';
import { globalFiltersUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors';
import { getData } from './merchandising.api';

function* getDataRequest() {
  try {
    const globalFilters = yield select(globalFiltersUrlParamsSelector);
    const response = yield call(getData, globalFilters);
    const data = response.data;
    yield put(MerchandisingActions.getDataSuccess(data));
  } catch (e) {
    yield put(MerchandisingActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(MerchandisingActions.getDataError(null));
    }
  }
}

export default function* merchandisingSaga() {
  yield takeLatest(MerchandisingTypes.GET_DATA_REQUEST, getDataRequest);
}
