import { createActions, createReducer } from 'reduxsauce';
import { fromJS, List, Record, Map } from 'immutable';
import * as React from "react";

export const { Types: RoutingInjectionTypes, Creators: RoutingInjectionActions } = createActions({
    getDataRequest: [],
    getDataSuccess: ['data', 'agents', 'tradePrograms', 'involvements'],
    getDataError: ['e'],
    getTouchPointsSuccess: ['data1', 'data2'],
    getTouchPointsError: [],
    actionFilter: ['key', 'value'],
    selectAgent: ['id', 'username', 'region', 'district', 'territory', 'role', 'pos_type'],
    selectValue: ['key', 'value', 'pos_type', 'DSD_NDSD'],
    unselectValue: ['key', 'value'],
    addMissionRequest: [],
    addMissionSuccess: ['data'],
    addMissionError: ['e'],
    deleteMissionRequest: ['id', 'touchpoint_new'],
    deleteMissionSuccess: [],
    deleteMissionError: ['e']
}, { prefix: 'ROUTING_INJECTION_' });

const RoutingInjectionRecord = new Record({
    data: null,
    agents: null,
    touchPoints: null,
    touchPointsSelection: null,
    filters: fromJS({
        role: 'TRE',
        pos_type: 'DSD',
        date: null,
        selectedIndex: -1,
        selectedAgent: null,
        shouldUpdate: true,
    }),
    agent: fromJS({
        id: null,
        username: null,
        region: null,
        district: null,
        territory: null,
        role: null,
    }),
    selection: fromJS({
        touchPoints: new List(),
        tasks: new List(),
        optionalTasks: new List()
    }),
    onlyLamp: false,
    onlyWholesaler: false,
    onlyDSD: false,
    staem_distib: false,
    tradePrograms: new List(),
    involvements: new List(),
    isLoading: false,
    error: false
});

const INITIAL_STATE = new RoutingInjectionRecord({});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data, agents, tradePrograms, involvements }) =>
    state
        .set('data', fromJS(data))
        .set('agents', fromJS(agents))
        .set('tradePrograms', fromJS(tradePrograms))
        .set('involvements', fromJS(involvements));

const getDataError = (state = INITIAL_STATE, action) => state;

const getTouchPointsSuccess = (state = INITIAL_STATE, { data1, data2 }) => state
    .set('touchPoints', fromJS(data1))
    .set('touchPointsSelection', fromJS(data2));

const getTouchPointsError = (state = INITIAL_STATE, action) => state;



const actionFilter = (state = INITIAL_STATE, { key, value }) => state
    .setIn(['filters', key], value)
    .setIn(['agent', 'username'], null)
    .setIn(['agent', 'id'], null);


const selectAgent = (state = INITIAL_STATE, { id, username, region, district, territory }) => {

    let role = state.get('filters').get('role');
    let pos_type = state.get('filters').get('pos_type');

    return state.setIn(['agent', 'id'], id)
        .setIn(['agent', 'username'], username)
        .setIn(['agent', 'region'], region)
        .setIn(['agent', 'district'], district)
        .setIn(['agent', 'territory'], territory)
        .setIn(['agent', 'role'], role)
        .setIn(['agent', 'pos_type'], pos_type)
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List([]))
        .setIn(['selection', 'optionalTasks'], new List([6]));
    /*
    if(role === 'TRE') {

        return state.setIn(['agent', 'id'], id)
        .setIn(['agent', 'username'], username)
        .setIn(['agent', 'region'], region)
        .setIn(['agent', 'district'], district)
        .setIn(['agent', 'territory'], territory)
        .setIn(['agent', 'role'], role)
        .setIn(['agent', 'pos_type'], pos_type)
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List([1, 14, 5, 15]))
        .setIn(['selection', 'optionalTasks'], new List([6]));
    }
    if(role === 'BBE') {

        return state.setIn(['agent', 'id'], id)
        .setIn(['agent', 'username'], username)
        .setIn(['agent', 'region'], region)
        .setIn(['agent', 'district'], district)
        .setIn(['agent', 'territory'], territory)
        .setIn(['agent', 'pos_type'], pos_type)
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List([1, 14, 3, 4, 5, 16]))
        .setIn(['selection', 'optionalTasks'], new List([6]));
    }
    */
    return state.setIn(['agent', 'id'], id)
        .setIn(['agent', 'username'], username)
        .setIn(['agent', 'region'], region)
        .setIn(['agent', 'district'], district)
        .setIn(['agent', 'territory'], territory)
        .setIn(['agent', 'role'], role)
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List([2, 3, 4, 5, 6]))
        .setIn(['selection', 'optionalTasks'], new List([6]));
};

const selectValue = (state = INITIAL_STATE, { key, value, pos_type, DSD_NDSD }) => {

    let role = state.get('filters').get('role');

    if (key === 'touchPoints') {
        if (pos_type === 'LAMP') {
            return state
            .updateIn(['selection', key], array => array.push(value))
            .set('onlyLamp', true)
            .set('onlyWholesaler', false)
            .setIn(['selection', 'tasks'], new List([6]));
        } else if (pos_type === 'WHOLESALER' || pos_type === 'RETAILER') {
            return state
                .updateIn(['selection', key], array => array.push(value))
                .set('onlyLamp', false)
                .set('onlyWholesaler', pos_type)
                .setIn(['selection', 'tasks'], new List([14, 3, 4, 5, 15]));
            /*if (pos_type === 'WHOLESALER') {
            if(role === 'TRE') {

                return state
                    .updateIn(['selection', key], array => array.push(value))
                    .set('onlyLamp', false)
                    .set('onlyWholesaler', true)
                    .setIn(['selection', 'tasks'], new List([14, 5, 15]));
            }
        } else if (pos_type === 'RETAILER') {
            if(role === 'BBE') {
                if(DSD_NDSD === 'DSD') {

                    return state
                        .updateIn(['selection', key], array => array.push(value))
                        .set('onlyLamp', false)
                        .set('onlyWholesaler', false)
                        .set('onlyDSD', true)
                        .setIn(['selection', 'tasks'], new List([14, 3, 4, 5, 16]));
                }
                else if(DSD_NDSD === 'NDSD') {

                    return state
                        .updateIn(['selection', key], array => array.push(value))
                        .set('onlyLamp', false)
                        .set('onlyWholesaler', false)
                        .set('onlyDSD', false)
                        .setIn(['selection', 'tasks'], new List([14, 3, 4, 5]));
                }
            }*/
        } else {
            return state.updateIn(['selection', key], array => array.push(value));
        }
    } else {
        return state.updateIn(['selection', key], array => array.push(value));
    }
};

const unselectValue = (state = INITIAL_STATE, { key, value }) => {
    if (key === 'touchPoints' && state.get('selection').get('touchPoints').size === 1) {
        let role = state.get('filters').get('role');
        return state
            .set('onlyLamp', false)
            .set('onlyWholesaler', false)
            .setIn(['selection', 'tasks'], new List([]))
            .setIn(['selection', 'optionalTasks'], new List([6]))
            .updateIn(['selection', key], array => {
                let index = array.indexOf(value);
                if (index !== -1) return array.delete(index);
                return array;
            });
        /*
        if(role === 'TRE') {
            return state
                .set('onlyLamp', false)
                .set('onlyWholesaler', false)
                .setIn(['selection', 'tasks'], new List([1, 14, 5, 15]))
                .setIn(['selection', 'optionalTasks'], new List([6]))
                .updateIn(['selection', key], array => {
                    let index = array.indexOf(value);
                    if (index !== -1) return array.delete(index);
                    return array;
                });
        }
        if (role === 'BBE') {
            return state
                .set('onlyLamp', false)
                .set('onlyWholesaler', false)
                .setIn(['selection', 'tasks'], new List([6]))
                .setIn(['selection', 'optionalTasks'], new List([6]))
                .updateIn(['selection', key], array => {
                    let index = array.indexOf(value);
                    if (index !== -1) return array.delete(index);
                    return array;
                });
        }
        */
    } else {
        return state.updateIn(['selection', key], array => {
            let index = array.indexOf(value);
            if (index !== -1) return array.delete(index);
            return array;
        });
    }
};

const addMissionRequest = (state = INITIAL_STATE, action) => state.set('isLoading', true).set('error', false);

const addMissionSuccess = (state = INITIAL_STATE, { data, agents }) => {
    /*state.set('isLoading', false)
      .set('error', false);
    state.setIn(['agent'], fromJS({
        id: null,
        username: null,
        region: null,
        district: null,
        territory: null,
    }))
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List());*/
   return  state.set('isLoading', false)
       .set('error', false)
       .setIn(['agent'], fromJS({
           id: null,
           username: null,
           region: null,
           district: null,
           territory: null,
           role: null,
       }))
       .setIn(['selection', 'touchPoints'], new List())
       .setIn(['selection', 'tasks'], new List());
}


const addMissionError = (state = INITIAL_STATE, action) => state.set('isLoading', false).set('error', true);

const deleteMissionRequest = (state = INITIAL_STATE, { id, touchpoint_new }) => {
    return state.setIn(['touchPointsSelection', 'touchpoints'], state.get('touchPointsSelection').get('touchpoints').delete(touchpoint_new));
};

const deleteMissionSuccess = (state = INITIAL_STATE, action) =>

    state.set('isLoading', false)
        .set('error', false)
        .setIn(['agent'], fromJS({
            id: null,
            username: null,
            region: null,
            district: null,
            territory: null,
            role: null,
        }))
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List());


const deleteMissionError = (state = INITIAL_STATE, action) => state;

const HANDLERS = {
    [RoutingInjectionTypes.GET_DATA_REQUEST]: getDataRequest,
    [RoutingInjectionTypes.GET_DATA_SUCCESS]: getDataSuccess,
    [RoutingInjectionTypes.GET_DATA_ERROR]: getDataError,
    [RoutingInjectionTypes.GET_TOUCH_POINTS_SUCCESS]: getTouchPointsSuccess,
    [RoutingInjectionTypes.GET_TOUCH_POINTS_ERROR]: getTouchPointsError,
    [RoutingInjectionTypes.ACTION_FILTER]: actionFilter,
    [RoutingInjectionTypes.SELECT_AGENT]: selectAgent,
    [RoutingInjectionTypes.SELECT_VALUE]: selectValue,
    [RoutingInjectionTypes.UNSELECT_VALUE]: unselectValue,
    [RoutingInjectionTypes.ADD_MISSION_REQUEST]: addMissionRequest,
    [RoutingInjectionTypes.ADD_MISSION_SUCCESS]: addMissionSuccess,
    [RoutingInjectionTypes.ADD_MISSION_ERROR]: addMissionError,
    [RoutingInjectionTypes.DELETE_MISSION_REQUEST]: deleteMissionRequest,
    [RoutingInjectionTypes.DELETE_MISSION_SUCCESS]: deleteMissionSuccess,
    [RoutingInjectionTypes.DELETE_MISSION_ERROR]: deleteMissionError
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);