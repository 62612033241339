import { createActions, createReducer } from 'reduxsauce';
import { fromJS, Record, Map } from 'immutable';

export const { Types, Creators } = createActions({
  getDataRequest: [],
}, {
  prefix: 'ACHIEVEMENTS_'
});

const state = new Record({});

const INITIAL_STATE = new state({});

const HANDLERS = {};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
