import React, { PureComponent } from 'react';
import SimpleLineIcon from 'react-simple-line-icons';
import { Container, Title, Text } from './localisation.styles';
import { LineContainer, LineTitle, LineValue } from './localisation.styles';
import map from '../../../../../images/map.png';


const Line = ({ title, value }) => {
  if (!value)
    return null;
  return (
    <LineContainer>
      <LineTitle>{title}</LineTitle>
      <LineValue>{value}</LineValue>
    </LineContainer>
  );
};

class Localisation extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    return (
      <Container>
        <Title>
          <SimpleLineIcon name={'location-pin'} color={'#4a4a4a'} style={{fontSize: '14px', marginRight: '8px'}} />
          <Text>Localisation</Text>
        </Title>
        <Line title={'Wilaya:'} value={data.get('wilaya')} />
        <Line title={'Commune:'} value={data.get('commune')} />
        <Line title={'Address:'} value={data.get('address')} />
        <Line title={'Localisation A:'} value={data.get('location_type_A') ? data.get('location_type_A').get('name') : null} />
        <Line title={'Localisation B:'} value={data.get('location_type_B') ? data.get('location_type_B').get('name') : null} />
        <Line title={'Localisation C:'} value={data.get('location_type_C') ? data.get('location_type_C').get('name') : null} />
        <img src={map} width={'100%'} />
      </Container>
    );
  }
}

export default Localisation;
