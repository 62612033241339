import React, { PureComponent } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import tpMarker from './tp-marker.png';
import beginMarker from './begin-marker.png';
import endMarker from './end-marker.png';

class Map extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tp: null,
      begin: null,
      end: null
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      tp: nextProps.tp,
      begin: nextProps.begin,
      end: nextProps.end
    });
  }
  componentDidMount() {
    const { tp, begin, end } = this.props;
    this.setState({
      tp,
      begin,
      end
    });
    if (this.map) {
      let bounds = new window.google.maps.LatLngBounds();
      if (tp)
        bounds.extend(new window.google.maps.LatLng(tp.lat, tp.lng));
      if (begin)
        bounds.extend(new window.google.maps.LatLng(begin.lat, begin.lng));
      if (end)
        bounds.extend(new window.google.maps.LatLng(end.lat, end.lng));
      this.map.fitBounds(bounds);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { tp, begin, end } = this.state;
    if (this.map) {
      let bounds = new window.google.maps.LatLngBounds();
      if (tp)
        bounds.extend(new window.google.maps.LatLng(tp.lat, tp.lng));
      if (begin)
        bounds.extend(new window.google.maps.LatLng(begin.lat, begin.lng));
      if (end)
        bounds.extend(new window.google.maps.LatLng(end.lat, end.lng));
      this.map.fitBounds(bounds);
    }
  }
  render() {
    const { tp, begin, end } = this.state;
    if (!tp && !begin && !end)
      return null;
    return (
      <GoogleMap
        ref={(ref) => { this.map = ref; }}
        defaultZoom={17}
        defaultCenter={tp}
      >
        <Marker position={tp} icon={tpMarker} />
        <Marker position={begin} icon={beginMarker} />
        <Marker position={end} icon={endMarker} />
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
