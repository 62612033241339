import React, { PureComponent } from 'react';
import { Container, Title, Image, LineContainer, LineTitle, LineValue } from './touch-point-details.styles';

const Line = ({ title, value }) => (
  <LineContainer>
    <LineTitle>{title}</LineTitle>
    <LineValue>{value}</LineValue>
  </LineContainer>
);

class TouchPointDetails extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Container>
        <Title>Touchpoint details</Title>
        <Image src={data.get('image')} />
        <Line title={'Name:'} value={data.get('name')} />
        <Line title={'Code:'} value={data.get('legacy_id')} />
        <Line title={'Segment:'} value={data.get('segment')} />
        <Line title={'Location:'} value={data.get('location')} />
        <Line title={'Address:'} value={data.get('address')} />
      </Container>
    );
  }
}

export default TouchPointDetails;
