import { api } from '../../services/api';

const TOUCHPOINTS_URL = '/touchpoints/';

const TERRITORIES_URL = '/territories/';

export const getData = (filters) =>
  api.get(`${TOUCHPOINTS_URL}?${filters}&for_review=true`);

export const getTerritories = () =>
  api.get(`${TERRITORIES_URL}`);

export const getDetails = (id) =>
  api.get(`${TOUCHPOINTS_URL}${id}/`);

export const patchTouchpoint = (id, confirmed, territory) => {
  if (territory) {
    return api.patch(`${TOUCHPOINTS_URL}${id}/`, {
      is_confirmed: confirmed,
      territory: territory
    });
  } else {
    return api.patch(`${TOUCHPOINTS_URL}${id}/`, {
      is_confirmed: confirmed
    });
  }
}
