import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
`;

export const Photo = styled.img`
  height: 169px;
  object-fit: contain;
  width: 100%;
`;

export const FooterContainer = styled.div`
  align-items: center;
  border-top: #f3f3f3 solid 1px;
  display: flex;
  height: 48px;
  padding-left: 16px;
`;

export const FooterTitle = styled.p`
  color: #a4a4a4;
  font-size: 14px;
  font-weight: normal;
  margin: 0;  
  padding-left: 4px;
`;

export const FooterText = styled.p`
  color: #555555;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding-left: 4px;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 16px;
`;

export const LogoContainer = styled.div`
  display: flex;
`;

export const Logo = styled.img`
  border: #ebebeb solid 1px;
  border-radius: 50%;
  height: 40px;
  margin-right: 16px;
  width: 40px;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title1 = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export const Title2 = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export const Count = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 26px;
  font-weight: 500;
  margin: 0;
`;
