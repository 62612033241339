import { all, call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { Actions as TouchpointValidationActions, Types as TouchpointValidationTypes } from './touchpoint-validation.redux';
import { globalFiltersWithoutDatesUrlParamsSelector } from '../../modules/root/global-filters/global-filters.selectors';
import { getData, getTerritories, getDetails, patchTouchpoint } from './touchpoint-validation.api';

function* getDataRequest() {
  try {
    const filters = yield select(globalFiltersWithoutDatesUrlParamsSelector);
    const [data, territories] = yield all([
      call(getData, filters),
      call(getTerritories)
    ]);
    yield put(TouchpointValidationActions.getDataSuccess(data.data, territories.data));
  } catch (e) {
    yield put(TouchpointValidationActions.getDataError(e));
  } finally {
    if (yield cancelled()) {
      yield put(TouchpointValidationActions.getDataError(null));
    }
  }
}

function* getDetailsRequest({ id }) {
  try {
    const response = yield call(getDetails, id);
    const data = response.data;
    yield put(TouchpointValidationActions.getDetailsSuccess(data));
  } catch (e) {
    yield put(TouchpointValidationActions.getDetailsError(e));
  } finally {
    if (yield cancelled()) {
      yield put(TouchpointValidationActions.getDetailsError(null));
    }
  }
}

function* _patchTouchpoint({ id, confirmed, territory }) {
  try {
    yield call(patchTouchpoint, id, confirmed, territory);
  } catch (e) {
    
  }
}

export default function* touchpointValidationSaga() {
  yield takeLatest(TouchpointValidationTypes.GET_DATA_REQUEST, getDataRequest);
  yield takeLatest(TouchpointValidationTypes.GET_DETAILS_REQUEST, getDetailsRequest);
  yield takeLatest(TouchpointValidationTypes.PATCH_TOUCHPOINT, _patchTouchpoint);
}
