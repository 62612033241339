import React, { PureComponent } from 'react';
import { Container, Title, Content, LinesContainer, } from './card.styles';
import { LineTitle, LineValue, LinePercent, Picture } from './card.styles';

const Line = ({ title, value, percent }) => (
  <>
    <LineTitle>{title}</LineTitle>
    <LineValue>{value}</LineValue>
  </>
);

class Card extends PureComponent {
  render() {
    const { product, handling, availability, buyPrice, price, stocks, image } = this.props;
    return (
      <Container>
        <Title>Product: {product}</Title>
        <Content>
          <Picture src={image}/>
          <LinesContainer>
            <Line title={'Handeling'} value={handling} />
            <Line title={'Availability'} value={availability} />
            <Line title={'Buy Price'} value={buyPrice} />
            <Line title={'Sell Price'} value={price} />
            <Line title={'Avg Stock'} value={stocks} />
          </LinesContainer>
        </Content>
      </Container>
    );
  }
}

export default Card;
