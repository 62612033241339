import { call, cancelled, put, takeLatest, select, all } from 'redux-saga/effects';
import {RoutingInjectionTypes, RoutingInjectionActions} from './routing-injection.redux';
import { getMissions, getAgents, getTouchPoints, postRouting, deleteMission, getTradePrograms, getInvolvements } from './routing-injection.api';
import {globalFiltersRoutingUrlParamsSelector} from '../../modules/root/global-filters/global-filters.selectors';
import {
    selectedAgentUsernameSelector,
    localFiltersUrlParamsSelector,
    touchPointsSelector,
    DSDSelector
} from './routing-injection.selectors';
import { dateSelector, selectedAgentSelector, selectedTouchPointsSelector, selectedTasksSelector } from './routing-injection.selectors';
import React from "react";

function* getDataRequest() {
    try {
        const globalFilters = yield select(globalFiltersRoutingUrlParamsSelector);
        const localFilters = yield select(localFiltersUrlParamsSelector);
        const [data, agents, tradePrograms, involvements] = yield all([
            call(getMissions, `${globalFilters}`),
            call(getAgents, `${localFilters}`),
            call(getTradePrograms),
            call(getInvolvements)
            //call(getTouchPoints, `${globalFilters}`)
        ]);
        yield put(RoutingInjectionActions.getDataSuccess(data.data, agents.data, tradePrograms.data.programs, involvements.data));
    } catch (e) {
        yield put(RoutingInjectionActions.getDataError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.getDataError(null));
        }
    }
}

function* selectAgent({ region, district, territory, role }) {

    const pos_type = yield select(DSDSelector);


    try {

        let filters = `region=${region}`;
        if (district)
            filters += `&district=${district}`;
        if (territory)
            filters += `&territory=${territory}`;
        if (role)
            filters += `&role=${role}`;
        if (pos_type)
            filters += `&pos_type=${pos_type}`;

        const date = yield select(dateSelector);
        const agent = yield select(selectedAgentUsernameSelector);

        const [response1, response2] = yield all([
            call(getTouchPoints, filters),
            call(getMissions, `date=${date}&agent=${agent}`),
        ]);

        const data1 = response1.data;
        const data2 = response2.data;
        yield put(RoutingInjectionActions.getTouchPointsSuccess(data1, data2));
    } catch (e) {
        yield put(RoutingInjectionActions.getTouchPointsError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.getTouchPointsError(null));
        }
    }
}

function* addMission() {
    try {
        const date = yield select(dateSelector);
        const agent = yield select(selectedAgentSelector);
        const touchpoints = yield select(selectedTouchPointsSelector);
        const tasks = yield select(selectedTasksSelector);
        let params = {
            date, agent, touchpoints, tasks
        };
        const response = yield call(postRouting, params);
        const data = response.data;
        yield put(RoutingInjectionActions.addMissionSuccess(data));

    } catch (e) {
        yield put(RoutingInjectionActions.addMissionError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.addMissionError(null));
        }
    }
}

function* deleteMissionRequest({ id }) {
    try {
        const response = yield call(deleteMission, id);
        const data = response.data;
        yield put(RoutingInjectionActions.deleteMissionSuccess());

    } catch (e) {
        yield put(RoutingInjectionActions.deleteMissionError(e));
    } finally {
        if (yield cancelled()) {
            yield put(RoutingInjectionActions.deleteMissionError(null));
        }
    }
}


function* actionFilter( type = 1 ) {

    const state = yield select();

    /*if( state.get('routingInjection').filters.get('selectedIndex') !== -1 ) {
        console.log( selectedAgent.get('territory') );
        let region = selectedAgent.get('region');
        let district = selectedAgent.get('district');
        let territory = selectedAgent.get('territory');
        let role = selectedAgent.get('role_secondary');
        let params = {
            region, district, territory, role
        };
        yield call( selectAgent, params);

        //yield put(RoutingInjectionActions.getDataRequest());
        //selectAgent( selectedAgent.get('region'), selectedAgent.get('district'), selectedAgent.get('territory'), selectedAgent.get('role_secondary') );
    } else if ( type === 2 ) {*/
        yield put(RoutingInjectionActions.getDataRequest());
    /*}*/
}

export default function* routingInjectionSaga() {
    yield takeLatest(RoutingInjectionTypes.GET_DATA_REQUEST, getDataRequest);
    yield takeLatest(RoutingInjectionTypes.SELECT_AGENT, selectAgent);
    yield takeLatest(RoutingInjectionTypes.ADD_MISSION_REQUEST, addMission);
    yield takeLatest(RoutingInjectionTypes.DELETE_MISSION_REQUEST, deleteMissionRequest);
    yield takeLatest(RoutingInjectionTypes.ACTION_FILTER, actionFilter);

}