import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(100, 109, 130, 0.14), 0 0 1px 0 rgba(100, 109, 130, 0.1);
  grid-column: 1 / 3;
  padding-bottom: 33px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 24px 0 24px 40px;
`;

export const Image = styled.img`
  height: 14px;
  width: 14px;
`;

export const Text = styled.h1`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 0 8px;
`;
