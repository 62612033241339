import { combineReducers } from 'redux-immutable';
import { reducer as achievementsReducer } from './achievements/achievements.redux';
import { reducer as activationsReducer } from './activations/activations.redux';
import { reducer as authenticationReducer } from './authentication/authentication.redux';
import { reducer as dashboardReducer } from './dashboard/dashboard.redux';
import { reducer as exportReducer } from './export/export.redux';
import { reducer as globalFiltersReducer } from './root/global-filters/global-filters.redux';
import { reducer as loadingReducer } from './root/loading/loading.redux';
import { reducer as materialReducer } from './material/material.redux';
import { reducer as merchandisingReducer } from './merchandising/merchandising.redux';
import { reducer as performanceReducer } from './performance/performance.redux';
import { reducer as planogramsReducer } from './planograms/planograms.redux';
import { reducer as routingReducer } from './routing/routing.redux';
import { reducer as routingInjectionReducer } from './routing-injection/routing-injection.redux';
import { reducer as teamReducer } from './team/team.redux';
import { reducer as touchpointsReducer } from './touchpoints/touchpoints.redux';
import { reducer as touchpointValidationReducer } from './touchpoint-validation/touchpoint-validation.redux';
import { reducer as visitsReducer } from './visits/visits.redux';

export const createReducer = () => combineReducers({
  achievements: achievementsReducer,
  activations: activationsReducer,
  authentication: authenticationReducer,
  dashboard: dashboardReducer,
  export: exportReducer,
  globalFilters: globalFiltersReducer,
  loading: loadingReducer,
  material: materialReducer,
  merchandising: merchandisingReducer,
  performance: performanceReducer,
  planograms: planogramsReducer,
  routing: routingReducer,
  routingInjection: routingInjectionReducer,
  team: teamReducer,
  touchpoints: touchpointsReducer,
  touchpointValidation: touchpointValidationReducer,
  visits: visitsReducer
});
