import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  padding: 24px 79px 80px 79px;
  overflow-y: auto;
`;
