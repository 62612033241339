import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { tokenSelector } from '../modules/authentication/authentication.selectors';

const requireAuthenticationComponent = (WrappedComponent) => {
    class requireAuthentication extends Component {
        render() {
            return this.props.token ?
                <WrappedComponent {...this.props} /> :
                <Redirect to='/authentication' />
        }
    }

    const mapStateToProps = createStructuredSelector({
        token: tokenSelector
    });

    return connect(mapStateToProps)(requireAuthentication)
};

export default requireAuthenticationComponent;