import { createSelector } from 'reselect';

export const dataSelector = state => state.get('activations').get('data');

export const filtersSelector = state => state.get('activations').get('filters');

export const selectedSelector = state => state.get('activations').get('selected');

const TYPES = {
  SWITCH: 'SWITCH',
  FAILURE: 'FAILURE',
  FIDELITY: 'FIDELITY'
};

export const localFiltersUrlParamsSelector = createSelector(
  selectedSelector,
  (selected) => {
    let urlParams = '';

    let types = selected.get('types');
    if (!types.isEmpty()) {
      urlParams += 'type=';
      types.map(
        type =>
          urlParams += type + ','
      );
      urlParams = urlParams.slice(0, -1);
      urlParams += '&'
    }

    let csm = selected.get('csm');
    if (!csm.isEmpty()) {
      urlParams += 'csm=';
      csm.map(
        csm =>
          urlParams += csm + ','
      );
      urlParams = urlParams.slice(0, -1);
      urlParams += '&'
    }

    if (urlParams.endsWith('&'))
      urlParams = urlParams.slice(0, -1);

    return urlParams;
  }
);
