import React, { PureComponent } from 'react';
import Icon from '@mdi/react';
import { mdiCircleOutline } from '@mdi/js';
import { Container, Title, TimeContainer, PointContainer, Label, DottedLine } from './header.styles';

class Header extends PureComponent {
  render() {
    const { title, beginTime, endTime, beginTimeColor, endTimeColor } = this.props;
    return (
      <Container>
        <TimeContainer>
          <PointContainer>
            <Icon path={mdiCircleOutline} size={'12.6px'} color={beginTimeColor}/>
            <Label>{beginTime}</Label>
          </PointContainer>
          <DottedLine/>
          <PointContainer>
            <Icon path={mdiCircleOutline} size={'12.6px'} color={endTimeColor}/>
            <Label>{endTime}</Label>
          </PointContainer>
        </TimeContainer>
        <Title>{title}</Title>
      </Container>
    );
  }
}

export default Header;
