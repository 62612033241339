import React, { PureComponent } from 'react';
import UserProfile from './user-profile';
import UserStats from './user-stats';
import UserLoginHistory from './user-login-history';
import { Container, PinContainer, Pin } from './user-infos.styles';

class UserInfos extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Container>
        <UserProfile data={data} />
        {/*<UserStats data={data} />*/}
        <UserLoginHistory data={data.get('logins')} />
        <PinContainer>
          {
            data.get('user') && data.get('user').get('pin')
          }
        </PinContainer>
      </Container>
    );
  }
}

export default UserInfos;
