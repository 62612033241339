import { api } from '../../services/api';

const AUTHENTICATE_URL = '/auth/';
const VERIFY_URL = '/verify/';
const LOGOUT_URL = '/logout/';

export const authenticate = (username, password) =>
  api.post(AUTHENTICATE_URL, {
    username,
    password
  });

export const verify = (auth_request, pin) =>
  api.post(VERIFY_URL, {
    auth_request,
    pin
  });

export const logout = () =>
  api.get(LOGOUT_URL);
