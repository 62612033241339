import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  grid-column: 1 / 2;
  grid-row: 2 / 4;
  padding: 24px;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
`;

export const UserContainer = styled.div`
  align-items: center;
  border-bottom: #e9e9e9 solid 1px;
  display: flex;
  height: 50px;
  width: 337px;
`;

export const UserInfos = styled.div`
  align-items: center;
  display: flex;
`;

export const UserName = styled.p`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: normal;
  margin: 0 8px 0 0;
`;

export const UserJob = styled.p`
  color: #34b0fd;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
`;

export const UserPhoto = styled.img`
  border-radius: 50%;
  height: 26px;
  margin-right: 16px;
`;
