import React, { PureComponent } from 'react';
import Header from '../../../components/visit-details/root/header';
import Card from './card';
import permanentPosMaterialIcon from '../../../images/permanent-pos-material-icon.png';
import temporaryPosMaterialIcon from '../../../images/temporary-pos-material-icon.png';
import { Container, TitleContainer, Image, Text, Grid } from './material.styles';

const Title = ({ icon, value }) => (
  <TitleContainer>
    <Image src={icon} />
    <Text>{value}</Text>
  </TitleContainer>
);

class Material extends PureComponent {
  render() {
    const { data } = this.props;
    if (!data)
      return null;
    const permanentMaterial = data.get('equipments').filter(
      equipment => equipment.get('category').get('type') === 'PERMANANT MATERIAL'
    );
    const temporaryMaterial = data.get('equipments').filter(
      equipment => equipment.get('category').get('type') === 'TEMPORARY MATERIAL'
    );
    return (
      <Container>
        <Header title={'Material'} beginTime={'10:32am'} endTime={'10:40am'} beginTimeColor={'#b45fff'} endTimeColor={'#0284fe'} />
        <Title icon={permanentPosMaterialIcon} value={'Permanent POS Material'}/>
        <Grid>
          {
            permanentMaterial.map(
              pm => pm.get('owner') && <Card tmo={pm.get('owner').get('id')} name1={pm.get('category').get('subtype')} name2={pm.get('communication').get('name')} count={pm.get('quantity')} image={pm.get('image')} />
            )
          }
        </Grid>
        <Title icon={temporaryPosMaterialIcon} value={'Temporary POS Material'}/>
        <Grid>
          {
            temporaryMaterial.map(
              tm => tm.get('owner') &&  <Card tmo={tm.get('owner').get('id')} name1={tm.get('category').get('subtype')} name2={tm.get('communication').get('name')} count={tm.get('quantity')} image={tm.get('image')} />
            )
          }
        </Grid>
      </Container>
    );
  }
}

export default Material;
