import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
`;

export const Title = styled.h1`
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  margin: 25px 0 40px 24px;
`;

export const List = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const LineContainer = styled.tr`
  height: 35px;
  :nth-child(even) {
    background-color: rgb(243,243,243, 0.37);
  }
`;

export const LineIcon= styled.td`
  text-align: center;
  width: 60px;
`;

export const LineText = styled.td`
  color: #4a4a4a;
  font-size: 11px;
  font-weight: normal;
`;
