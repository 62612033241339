import { cancelled, call, put, takeLatest } from 'redux-saga/effects';
import { Actions as GlobalFiltersActions, Types as GlobalFiltersTypes } from './global-filters.redux';
import { Actions as DashboardActions } from '../../dashboard/dashboard.redux';
import { Actions as TouchpointsActions } from '../../touchpoints/touchpoints.redux';
import { Actions as TouchpointValidationActions } from '../../touchpoint-validation/touchpoint-validation.redux';
import { Actions as MerchandisingActions } from '../../merchandising/merchandising.redux';
import { Actions as ActivationsActions } from '../../activations/activations.redux';
import { Actions as MaterialActions } from '../../material/material.redux';
import { Actions as PlanogramsActions } from '../../planograms/planograms.redux';
import { RoutingActions } from '../../routing/routing.redux';
import { RoutingInjectionActions } from '../../routing-injection/routing-injection.redux';
import { Actions as PerformanceActions } from '../../performance/performance.redux';
import { Actions as ExportActions } from '../../export/export.redux';
import { Actions as VisitsActions } from '../../visits/visits.redux';
import { getFilters } from './global-filters.api';

function* getFiltersRequest() {
  try {
    const response = yield call(getFilters);
    const filters = response.data;
    yield put(GlobalFiltersActions.getFiltersSuccess(filters));
  } catch (e) {
    yield put(GlobalFiltersActions.getFiltersError(e));
  } finally {
    if (yield cancelled()) {
      yield put(GlobalFiltersActions.getFiltersError(null));
    }
  }
}

function* updateData() {
  const location = window.location.pathname.substr(1);
  switch (location) {
    case 'dashboard':
      yield put(DashboardActions.getDataRequest());
      break;
    case 'touchpoints':
      yield put(TouchpointsActions.getDataRequest());
      break;
    case 'touchpoint-validation':
      yield put(TouchpointValidationActions.getDataRequest());
      break;
    case 'merchandising':
      yield put(MerchandisingActions.getDataRequest());
      break;
    case 'activations':
      yield put(ActivationsActions.getDataRequest());
      break;
    case 'material':
      yield put(MaterialActions.getDataRequest());
      break;
    case 'planograms':
      yield put(PlanogramsActions.getDataRequest());
      break;
    case 'routing/form':
      yield put(RoutingActions.getDataRequest());
      break;
    case 'routing':
      yield put(RoutingInjectionActions.getDataRequest());
      break;
    case 'performance':
      yield put(PerformanceActions.getDataRequest());
      break;
    case 'export':
      yield put(ExportActions.getDataRequest());
      break;
    case 'visits':
      yield put(VisitsActions.getDataRequest());
      break;
  }
}

export default function* globalFiltersSaga() {
  yield takeLatest(GlobalFiltersTypes.GET_FILTERS_REQUEST, getFiltersRequest);
  yield takeLatest(GlobalFiltersTypes.GET_FILTERS_SUCCESS, updateData);
  yield takeLatest(GlobalFiltersTypes.SELECT_TAG, updateData);
  yield takeLatest(GlobalFiltersTypes.UNSELECT_TAG, updateData);
  yield takeLatest(GlobalFiltersTypes.UPDATE_RANGE_TIME, updateData);
}
