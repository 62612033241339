import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions as LoadingActions } from '../../../modules/root/loading/loading.redux';
import LoadingRoute from './loading-route.component';

const mapDispatchToProps = dispatch => bindActionCreators({
  start: LoadingActions.start,
  end: LoadingActions.end
}, dispatch);

export default connect(null, mapDispatchToProps)(LoadingRoute);
