import { createActions, createReducer } from 'reduxsauce';
import { Record } from 'immutable';
import { setAuthorization } from '../../services/api';
import { AuthenticationStages } from './authentication.constants';

export const { Types, Creators } = createActions({
  authenticateRequest: ['username','password'],
  authenticateSuccess: ['token', 'username'],
  authenticateError: ['e'],
  verifyRequest: ['auth_request', 'pin'],
  verifySuccess: ['token', 'username'],
  verifyError: ['e'],
  logoutRequest: [],
  logoutSuccess: [],
  logoutError: ['e']
}, {
  prefix: 'AUTHENTICATION_'
});

const state = new Record({
  auth_request: null,
  username: null,
  password: null,
  stage: null,
  token: null
});

const INITIAL_STATE = new state({
  auth_request: 0,
  username: localStorage.getItem('username'),
  password: localStorage.getItem('password'),
  token: localStorage.getItem('token'),
  stage: AuthenticationStages.REQUEST
});

const authenticateRequest = (state = INITIAL_STATE, action) => state;

const authenticateSuccess = (state = INITIAL_STATE, { token, username }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    setAuthorization();
    return state.set('token', token)
        .set('username', username)
        .set('stage', AuthenticationStages.AUTHENTICATED);
};

const authenticateError = (state = INITIAL_STATE, action) => state;

const verifyRequest = (state = INITIAL_STATE, action) => state;

const verifySuccess = (state = INITIAL_STATE, { token, username }) => {
  localStorage.setItem('token', token);
  localStorage.setItem('username', username);
  setAuthorization();
  return state.set('token', token)
    .set('username', username)
    .set('stage', AuthenticationStages.AUTHENTICATED);
};

const verifyError = (state = INITIAL_STATE, action) => state;

const logoutRequest = (state = INITIAL_STATE, action) => state;

const logoutSuccess = (state = INITIAL_STATE, action) => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  return state;
};

const logoutError = (state = INITIAL_STATE, action) => state;

const HANDLERS = {
  [Types.AUTHENTICATE_REQUEST]: authenticateRequest,
  [Types.AUTHENTICATE_SUCCESS]: authenticateSuccess,
  [Types.AUTHENTICATE_ERROR]: authenticateError,
  [Types.VERIFY_REQUEST]: verifyRequest,
  [Types.VERIFY_SUCCESS]: verifySuccess,
  [Types.VERIFY_ERROR]: verifyError,
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_ERROR]: logoutError
};

export const Actions = {...Types, ...Creators};
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
