import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createReducer } from './reducer';
import rootSaga from './saga';

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    sagaMiddleware
  ];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createReducer(),
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
