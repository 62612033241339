import { createActions, createReducer } from 'reduxsauce';
import { fromJS, List, Record, Map } from 'immutable';

export const { Types: RoutingTypes, Creators: RoutingActions } = createActions({
    getDataRequest: [],
    getDataSuccess: ['data', 'agents', 'tradePrograms', 'involvements'],
    getDataError: ['e'],
    getTouchPointsSuccess: ['data1', 'data2'],
    getTouchPointsError: [],
    actionFilter: ['key', 'value'],
    selectAgent: ['id', 'username', 'region', 'district', 'territory', 'role'],
    selectValue: ['key', 'value', 'pos_type'],
    unselectValue: ['key', 'value'],
    addMissionRequest: [],
    addMissionSuccess: ['data'],
    addMissionError: ['e'],
    deleteMissionRequest: ['id', 'touchpoint_new'],
    deleteMissionSuccess: [],
    deleteMissionError: ['e']
}, { prefix: 'ROUTING_' });

const RoutingRecord = new Record({
    data: null,
    agents: null,
    touchPoints: null,
    touchPointsSelection: null,
    filters: fromJS({
        role: 'TRE',
        date: null,
    }),
    agent: fromJS({
        id: null,
        username: null,
        region: null,
        district: null,
        territory: null,
    }),
    selection: fromJS({
        touchPoints: new List(),
        tasks: new List(),
        optionalTasks: new List()
    }),
    onlyLamp: false,
    tradePrograms: new List(),
    involvements: new List(),
    isLoading: false,
    error: false
});

const INITIAL_STATE = new RoutingRecord({});

const getDataRequest = (state = INITIAL_STATE, action) => state;

const getDataSuccess = (state = INITIAL_STATE, { data, agents, tradePrograms, involvements }) =>
    state
        .set('data', fromJS(data))
        .set('agents', fromJS(agents))
        .set('tradePrograms', fromJS(tradePrograms))
        .set('involvements', fromJS(involvements));

const getDataError = (state = INITIAL_STATE, action) => state;

const getTouchPointsSuccess = (state = INITIAL_STATE, { data1, data2 }) => state
    .set('touchPoints', fromJS(data1))
    .set('touchPointsSelection', fromJS(data2));

const getTouchPointsError = (state = INITIAL_STATE, action) => state;

const actionFilter = (state = INITIAL_STATE, { key, value }) => state
    .setIn(['filters', key], value)
    .setIn(['agent', 'username'], null)
    .setIn(['agent', 'id'], null);


const selectAgent = (state = INITIAL_STATE, { id, username, region, district, territory }) => {
    let role = state.get('filters').get('role');
    if(role === 'SFF') {
        return state.setIn(['agent', 'id'], id)
        .setIn(['agent', 'username'], username)
        .setIn(['agent', 'region'], region)
        .setIn(['agent', 'district'], district)
        .setIn(['agent', 'territory'], territory)
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List([1, 2, 3, 4, 5, 6]))
        .setIn(['selection', 'optionalTasks'], new List([6]));
    }
    if(role === 'CFF') {
        return state.setIn(['agent', 'id'], id)
        .setIn(['agent', 'username'], username)
        .setIn(['agent', 'region'], region)
        .setIn(['agent', 'district'], district)
        .setIn(['agent', 'territory'], territory)
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List([6]))
        .setIn(['selection', 'optionalTasks'], new List([6]));
    }
    return state.setIn(['agent', 'id'], id)
        .setIn(['agent', 'username'], username)
        .setIn(['agent', 'region'], region)
        .setIn(['agent', 'district'], district)
        .setIn(['agent', 'territory'], territory)
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List([1, 2, 3, 4, 5, 6]))
        .setIn(['selection', 'optionalTasks'], new List([6]));
};

const selectValue = (state = INITIAL_STATE, { key, value, pos_type }) => {
    if (key === 'touchPoints') {
        if (pos_type === 'LAMP') {
            return state
            .updateIn(['selection', key], array => array.push(value))
            .set('onlyLamp', true)
            .setIn(['selection', 'tasks'], new List([6]));
        } else {
            return state.updateIn(['selection', key], array => array.push(value));
        }
    } else {
        return state.updateIn(['selection', key], array => array.push(value));
    }
};

const unselectValue = (state = INITIAL_STATE, { key, value }) => {
    if (key === 'touchPoints' && state.get('selection').get('touchPoints').size === 1) {
        let role = state.get('filters').get('role');
        if(role === 'SFF') {
            return state
                .set('onlyLamp', false)
                .setIn(['selection', 'tasks'], new List([2, 3, 4, 5, 6]))
                .setIn(['selection', 'optionalTasks'], new List([6]))
                .updateIn(['selection', key], array => {
                    let index = array.indexOf(value);
                    if (index !== -1) return array.delete(index);
                    return array;
                });
        }
        if (role === 'CFF') {
            return state
                .set('onlyLamp', false)
                .setIn(['selection', 'tasks'], new List([6]))
                .setIn(['selection', 'optionalTasks'], new List([6]))
                .updateIn(['selection', key], array => {
                    let index = array.indexOf(value);
                    if (index !== -1) return array.delete(index);
                    return array;
                });
        }
    } else {
        return state.updateIn(['selection', key], array => {
            let index = array.indexOf(value);
            if (index !== -1) return array.delete(index);
            return array;
        });
    }
};

const addMissionRequest = (state = INITIAL_STATE, action) => state.set('isLoading', true).set('error', false);

const addMissionSuccess = (state = INITIAL_STATE, { data, agents }) =>
    state.set('isLoading', false)
      .set('error', false);
    /*state.setIn(['agent'], fromJS({
        id: null,
        username: null,
        region: null,
        district: null,
        territory: null,
    }))
        .setIn(['selection', 'touchPoints'], new List())
        .setIn(['selection', 'tasks'], new List());*/

const addMissionError = (state = INITIAL_STATE, action) => state.set('isLoading', false).set('error', true);

const deleteMissionRequest = (state = INITIAL_STATE, { id, touchpoint_new }) => {
    return state.setIn(['touchPointsSelection', 'touchpoints'], state.get('touchPointsSelection').get('touchpoints').delete(touchpoint_new));
};

const deleteMissionSuccess = (state = INITIAL_STATE, action) => state;

const deleteMissionError = (state = INITIAL_STATE, action) => state;

const HANDLERS = {
    [RoutingTypes.GET_DATA_REQUEST]: getDataRequest,
    [RoutingTypes.GET_DATA_SUCCESS]: getDataSuccess,
    [RoutingTypes.GET_DATA_ERROR]: getDataError,
    [RoutingTypes.GET_TOUCH_POINTS_SUCCESS]: getTouchPointsSuccess,
    [RoutingTypes.GET_TOUCH_POINTS_ERROR]: getTouchPointsError,
    [RoutingTypes.ACTION_FILTER]: actionFilter,
    [RoutingTypes.SELECT_AGENT]: selectAgent,
    [RoutingTypes.SELECT_VALUE]: selectValue,
    [RoutingTypes.UNSELECT_VALUE]: unselectValue,
    [RoutingTypes.ADD_MISSION_REQUEST]: addMissionRequest,
    [RoutingTypes.ADD_MISSION_SUCCESS]: addMissionSuccess,
    [RoutingTypes.ADD_MISSION_ERROR]: addMissionError,
    [RoutingTypes.DELETE_MISSION_REQUEST]: deleteMissionRequest,
    [RoutingTypes.DELETE_MISSION_SUCCESS]: deleteMissionSuccess,
    [RoutingTypes.DELETE_MISSION_ERROR]: deleteMissionError
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);